import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Routes, Route, Outlet, useParams } from 'react-router-dom';

import Offers from './offers';
import Offer, { OfferLatest } from './offer';
import { OfferCreate } from './newoffer';
import { EOfferSource } from '__generated__/graphql';

export default function OffersIndex() {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route path="widget" element={<Offers offerSource={EOfferSource.WIDGET}/>} />
              <Route path="lister" element={<Offers offerSource={EOfferSource.LISTER}/>} />
              <Route path="_create_widget" element={<OfferCreate offerSource={EOfferSource.WIDGET}/>} />
              <Route path="_create_lister" element={<OfferCreate offerSource={EOfferSource.LISTER}/>} />
              <Route path=":id" Component={() => <OfferLatest id={parseInt(useParams().id!)} />} />
              <Route path=":id/:versionId" Component={() => <Offer id={parseInt(useParams().id!)} versionId={parseInt(useParams().versionId!)} />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
