import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/userSlice';
import { user2IUser } from 'helper/security';
import LoginImage from 'assets/images/login.jpg';

import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { gql } from '__generated__/gql';
import { dispatchMessage } from 'helper/snackbar';

import { FormInputText, FormInputPassword } from 'components/form/FormInputText';

import { CHANGE_USERPASSWORD_MUTATION } from 'pages/settings/gql';
import { ErrorAlert } from 'pages/error';

export const LOGIN_MUTATION = gql(`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      user {
        ...UserFull
      }
    }
  }
`);

export const FORGOTPASSWORD_MUTATION = gql(`
  mutation ForgotUserPasswordMutation($email: String!) {
    forgotUserPassword(email: $email)
  }
`);

export const LOGOUT_MUTATION = gql(`
  mutation LogoutMutation {
    logout
  }
`);

export const ME_QUERY = gql(`
  query MeQuery {
    me {
      ...UserFull
    }
    listSpaces {
      ...SpaceFull
    }
    listHotels {
      ...HotelListShort
    }
  }
`);

const validationSchema = yup.object().shape({
  email: yup.string().required().label(i18next.t('login-email')),
  password: yup.string().required().label(i18next.t('login-password')),
});
const forgotValidationSchema = yup.object().shape({
  email: yup.string().required().label(i18next.t('login-email')),
});
const changePasswordValidationSchema = yup.object().shape({
  newPassword1: yup.string().required(),
  newPassword2: yup
    .string()
    .oneOf([yup.ref('newPassword1')], () => `${i18next.t('me-password-not-matching')}`)
    .required(),
});

interface LoginFormProps {
  passwordResetCode?: string | null;
}

export default function LoginForm(props: LoginFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [resetMutation] = useMutation(CHANGE_USERPASSWORD_MUTATION);
  const [resetError, setResetError] = useState<any>();

  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [loginError, setLoginError] = useState<any>();
  const [forgotHint, setForgotHint] = useState<string | null>();
  const [meQuery, { loading: meLoading }] = useLazyQuery(ME_QUERY);

  const [forgotMutation] = useMutation(FORGOTPASSWORD_MUTATION);
  const [forgotError, setForgotError] = useState<any>();
  const [showForgot, setShowForgot] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    defaultValues: { email: '', password: '' },
  });

  const {
    control: forgotControl,
    handleSubmit: forgotHandleSubmit,
    formState: { isSubmitting: forgotIsSubmitting, isValid: forgotIsValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(forgotValidationSchema) as any,
    defaultValues: { email: '' },
  });

  const {
    control: resetControl,
    handleSubmit: resetHandleSubmit,
    formState: { isSubmitting: resetIsSubmitting, isValid: resetIsValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(changePasswordValidationSchema) as any,
    defaultValues: { newPassword1: '', newPassword2: '' },
  });

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${LoginImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {props.passwordResetCode && (
            <Grid
              container
              onSubmit={resetHandleSubmit(async data => {
                setResetError(null);
                try {
                  const res = await resetMutation({
                    variables: {
                      passwordResetCode: props.passwordResetCode!,
                      newPassword: data.newPassword1!,
                    },
                  });
                  if (res && res.data && res.data.changeUserPassword) {
                    dispatchMessage(dispatch, `${i18next.t('login-reset-ready')}`);
                    navigate('/login', { replace: true });
                  } else {
                    setResetError(`${i18next.t('login-reset-failed')}`);
                  }
                } catch (err) {
                  setResetError(err);
                }
              })}
              component="form"
              noValidate
            >
              <Grid item xs={12}>
                <Typography variant="h5">{i18next.t('login-reset-title')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormInputPassword
                  name="newPassword1"
                  control={resetControl}
                  label={i18next.t('me-password-newpassword1')}
                  textFieldProps={{
                    margin: 'normal',
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputPassword
                  name="newPassword2"
                  control={resetControl}
                  label={i18next.t('me-password-newpassword2')}
                  textFieldProps={{
                    margin: 'normal',
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" fullWidth disabled={!resetIsValid || resetIsSubmitting} variant="contained" sx={{ mt: 3, mb: 2 }}>
                  {i18next.t('login-reset')}
                </Button>
              </Grid>
              {resetError && (
                <Grid item xs={12}>
                  <ErrorAlert err={resetError} />
                </Grid>
              )}
            </Grid>
          )}
          {!props.passwordResetCode && (
            <>
              <Grid
                container
                onSubmit={handleSubmit(async data => {
                  setLoginError(null);
                  try {
                    const res = await loginMutation({
                      variables: { email: data.email, password: data.password },
                    });
                    if (res && res.data && res.data.login.success && res.data.login.user) {
                      const meRes = await meQuery({
                        fetchPolicy: 'network-only',
                      });
                      if (meRes && meRes.data) {
                        const user = res.data.login.user;
                        dispatchMessage(dispatch, `${i18next.t('login-ready')}`);
                        dispatch(setUser(user2IUser(user, meRes.data.listSpaces, meRes.data.listHotels)));
                        navigate(user.space ? '/offers/lister' : '/', {
                          replace: true,
                        });
                      } else {
                        setLoginError(`${i18next.t('login-failed')}`);
                      }
                    } else {
                      setLoginError(`${i18next.t('login-failed')}`);
                    }
                  } catch (err) {
                    setLoginError(err);
                  }
                })}
                component="form"
                noValidate
              >
                <Grid item xs={12}>
                  <Typography variant="h5">{i18next.t('login-title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="email"
                    control={control}
                    label={i18next.t('login-email')}
                    textFieldProps={{
                      autoComplete: 'email',
                      autoFocus: true,
                      margin: 'normal',
                    }}
                    disabled={showForgot}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputPassword
                    name="password"
                    control={control}
                    label={i18next.t('login-password')}
                    textFieldProps={{
                      autoComplete: 'current-password',
                      margin: 'normal',
                    }}
                    disabled={showForgot}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" fullWidth disabled={showForgot || !isValid || isSubmitting} variant="contained" sx={{ mt: 3, mb: 2 }}>
                    {i18next.t('login-login')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs>
                      <Button variant="text" onClick={() => setShowForgot(!showForgot)}>
                        {i18next.t('login-forgot-password')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" href="https://sem.seminargo.com/#preise">
                        {i18next.t('login-no-account')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {loginError && (
                  <Grid item xs={12}>
                    <ErrorAlert err={loginError} />
                  </Grid>
                )}
              </Grid>

              {showForgot && (
                <Grid
                  container
                  onSubmit={forgotHandleSubmit(async data => {
                    setForgotError(null);
                    try {
                      await forgotMutation({
                        variables: { email: data.email },
                      });
                      setForgotError(`${i18next.t('login-forgot-ready')}`);
                    } catch (err) {
                      setForgotError(err);
                    }
                  })}
                  component="form"
                  sx={{ mt: 1 }}
                >
                  <Grid item xs={12}>
                    <FormInputText
                      name="email"
                      control={forgotControl}
                      label={i18next.t('login-email')}
                      textFieldProps={{
                        autoComplete: 'email',
                        autoFocus: true,
                        margin: 'normal',
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" fullWidth disabled={!forgotIsValid || forgotIsSubmitting} variant="contained" sx={{ mt: 3, mb: 2 }}>
                      {i18next.t('login-forgot-send')}
                    </Button>
                  </Grid>
                  {forgotError && (
                    <Grid item xs={12}>
                      <ErrorAlert err={forgotError} />
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
