import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Routes, Route, Outlet, useParams } from 'react-router-dom';

import { EOfferSource } from '__generated__/graphql';
import TasksBoard from './tasks';

export default function TasksIndex() {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route path="" element={<TasksBoard />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
