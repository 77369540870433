import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Grid, CircularProgress, IconButton, Select, MenuItem, Button } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import LoginIcon from '@mui/icons-material/Login';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../validation';

import { dispatchException, dispatchMessage } from '../../helper/snackbar';

import { FormInputText } from '../../components/form/FormInputText';
import { FormInputCheckbox } from '../../components/form/FormInputCheckbox';
import { buildMenuItemsFromOptions } from '../../components/form/FormInputDropdown';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from '../../components/security/SpaceSelectionInput';
import { UnsavedChangesPrompt } from '../../components/form/UnsavedChangesPrompt';
import ConfirmationButton from '../../components/dialogs/ConfirmationButton';
import CustomTabs from '../../components/Tabs';
import SimpleTable from '../../components/table/SimpleTable';
import { FormInputDropdown } from '../../components/form/FormInputDropdown';

import { CREATE_TASK_CATEGORY, CREATE_TASK_CATEGORY_GROUP, LIST_TASK_CATEGORIES, LIST_TASK_CATEGORY_GROUPS, UPDATE_TASK_CATEGORY, UPDATE_TASK_CATEGORY_GROUP, VIEW_TASK_CATEGORY, VIEW_TASK_CATEGORY_GROUP } from './gql';
import { FormInputFileUpload } from '../../components/form/FormInputFileUpload';
import { TM_TaskCategoryGroupOutput } from '__generated__/graphql';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import { canEditAdminRecord, userSelector } from 'helper/security';
import { RedirectError } from 'pages/error';

interface TasksGroupProps {
  id: number;
}
interface TasksGroupCreateProps {}
interface TasksGroupFormProps {
  data: TM_TaskCategoryGroupOutput;
}

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  name: yup.string().required(),
  disabled: yup.boolean().required(),
  system: yup.boolean().required()
});

function TasksGroupForm(props: TasksGroupFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = useApolloClient();

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/tasks/settings/groups/${createdId}`);
  }, [createdId]);

  const user = userSelector()!;
  const canEdit = user.isAdmin

  type GroupFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: TM_TaskCategoryGroupOutput): GroupFormType => ({
    ...obj,
    isCreate: props.data.id > 0 ? false : true,
    name: obj.name || '',
    disabled: obj.disabled || false,
    system: obj.system || false
  });

  const [createMutateFunction] = useMutation(CREATE_TASK_CATEGORY_GROUP)
  const [updateMutateFunction] = useMutation(UPDATE_TASK_CATEGORY_GROUP)

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    getValues,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client },
    defaultValues: toFormSchema((props.data || {}) as TM_TaskCategoryGroupOutput),
  });
  const formValues = getValues();

  const onSubmit = async (values: GroupFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              disabled: values.disabled
            },
          },
          refetchQueries: [
            {
              query: VIEW_TASK_CATEGORY,
              variables: { id: props.data.id },
            },
            {
              query: LIST_TASK_CATEGORY_GROUPS,
            },
            {
              query: LIST_TASK_CATEGORIES,
            }
          ]
        });
        reset(toFormSchema((res.data!.updateTaskCategoryGroup || {}) as TM_TaskCategoryGroupOutput));
        dispatchMessage(dispatch, 'Kategorie aktualisiert');
      } else {
        const res = await createMutateFunction({
          variables: {
            data: {
              name: values.name,
              disabled: values.disabled
            },
          },
          refetchQueries: [
            {
              query: LIST_TASK_CATEGORY_GROUPS,
            },
            {
              query: LIST_TASK_CATEGORIES,
            }
          ]
        });
        reset(toFormSchema((res.data!.createTaskCategoryGroup || {}) as TM_TaskCategoryGroupOutput));
        setCreatedId(res.data!.createTaskCategoryGroup?.id || 0);
        dispatchMessage(dispatch, 'Kategorie erstellt');
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Tasks / Einstellungen / Bereiche / {props.data.id > 0 ? props.data.name : 'Neu'}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={4}>
          <FormInputText name="name" control={control} label="Name" required disabled={!canEdit} />
        </Grid>
        <Grid item sm={8} />
        <Grid item xs={12} sm={4}>
          <FormInputCheckbox name="disabled" control={control} label="Deaktiviert" disabled={props.data.system} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputCheckbox name="system" control={control} label="Systemrelevant" disabled={true} />
        </Grid>
        <Grid item sm={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting || !canEdit}
          >
            Speichern
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default function TasksGroupUpdate(props: TasksGroupProps) {
  const categoryQuery = useQuery(VIEW_TASK_CATEGORY_GROUP, {
    variables: { id: props.id },
  });

  const loading = categoryQuery.loading;
  const error = categoryQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <TasksGroupForm data={categoryQuery.data?.viewTaskCategoryGroup as TM_TaskCategoryGroupOutput} />;
}

export function TasksGroupCreate(props: TasksGroupCreateProps) {

  return (
    <TasksGroupForm
      data={{
        id: -1,
        name: '',
        disabled: false,
        system: false
      }}
    />
  );
}
