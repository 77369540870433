import Handlebars from 'handlebars';

async function _replaceAsync(str: any, regex: any, asyncFn: any) {
  const promises: any[] = [];
  str.replace(regex, (match: any, ...args: any) => {
    const promise = asyncFn(match, ...args);
    promises.push(promise);
  });
  const data = await Promise.all(promises);
  return str.replace(regex, () => data.shift());
}

export type TResolveMediaSrc = (mediaName: string) => Promise<string | null>;

Handlebars.registerHelper('toString', function (num: number) {
  return num.toString();
});

Handlebars.registerHelper('formatPrice', function (num: number) {
  return num.toFixed(2).toString().replace('.', ',');
});

Handlebars.registerHelper('inc', function (value: string) {
  return parseInt(value) + 1;
});

Handlebars.registerHelper('dec', function (value: string) {
  return parseInt(value) - 1;
});

Handlebars.registerHelper('set', function (value: any) {
  return value ? true : false;
});

Handlebars.registerHelper('notset', function (value: any) {
  return value ? false : true;
});

Handlebars.registerHelper('empty', function (value: any) {
  return value.length === 0;
});

Handlebars.registerHelper('notempty', function (value: any) {
  return value.length > 0;
});

Handlebars.registerHelper('or', function (this: any, a: any, b: any, options: Handlebars.HelperOptions) {
  if (!!a || !!b) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('and', function (this: any, a: any, b: any, options: Handlebars.HelperOptions) {
  if (!!a && !!b) {
    return options.fn(this); 
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('ifgte', function (this: any, a: any, b: any, options: Handlebars.HelperOptions) {
  if (a >= b) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('ifgt', function (this: any, a: any, b: any, options: Handlebars.HelperOptions) {
  if (a > b) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper('json', function (obj: any) {
  return JSON.stringify(obj, null, 2);
});

export const handlebars = async (template: string, data: any, resolveMediaSrc?: TResolveMediaSrc | null) => {
  let templateToCompile = template;

  if (resolveMediaSrc) {
    const regex = /({{\[(.*)\]}})/g;
    templateToCompile = await _replaceAsync(template, regex, async (match: any, tag: any, mediaName: any) => {
      const mediaSrc = await resolveMediaSrc(mediaName);
      if (mediaSrc) {
        return mediaSrc;
      } else {
        return `Media "${mediaName}" not found`;
      }
    });
  }

  const h = Handlebars.compile(templateToCompile);
  return h(data);
};
