import { gql } from "__generated__";

export const PUBLIC_CLIENT_LIST_SEARCH_QUERY = gql(`
  query SearchPublicClients($query: String!) {
    searchPublicClients(query: $query) {
      ...PublicClientListShort
    }
  }
`);


export const PUBLIC_COMPANIES_LIST_SEARCH_QUERY = gql(`
  query SearchPublicCompanies($query: String!) {
    searchPublicCompanies(query: $query) {
      ...PublicCompanyListShort
    }
  }
`);

export const BOOKINGS_LIST_SEARCH_QUERY = gql(`
  query SearchBookings($query: String!) {
    searchBookings(query: $query) {
      ...BookingListOutput
    }
  }
`);

export const HOTELS_LIST_SEARCH_QUERY = gql(`
  query SearchHotels($query: String!) {
    searchHotels(query: $query) {
      ...HotelListShort
    }
  }
`);