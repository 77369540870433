import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Grid } from '@mui/material';

import { useLazyQuery, useQuery, useQueryRefHandlers } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { PUBLIC_CLIENT_LIST_SEARCH_QUERY } from './gql';

import { ErrorAlert, RedirectError } from 'pages/error';
import SimpleTable from 'components/table/SimpleTable';
import { HL_PublicClientOutput } from '__generated__/graphql';

interface ClientsListProps {
  clients: HL_PublicClientOutput[];
}

export default function ClientsList(props: ClientsListProps) {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  return (
    <>
      <Helmet>
        <title>{i18next.t('clients-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SimpleTable
            headers={[
              i18next.t('clients-list-header-name'),
              i18next.t('clients-list-header-email'),
              i18next.t('clients-list-header-phone'),
              i18next.t('clients-list-header-company'),
              i18next.t('clients-list-header-premium'),
            ]}
            rows={(props.clients || []).map((row, index) => [
              <Link to={`/clients/clients/${row.id}`}>{row.firstname + ' ' + row.lastname}</Link>,
              row.email,
              row.phone || '-',
              <Link to={`/clients/companies/${row.publicCompany?.id}`}>{row.publicCompany?.name}</Link>,
              row.publicCompany?.isPremium ? <CheckIcon /> : '-',
            ])}
          />
        </Grid>
      </Grid>
    </>
  );
}
