import _ from 'lodash';

export type MediaSelectorInput = {
  mediaName: string;
};
export type MediaSelectorOutput = {
  media: IMedia;
  url: string;
}

export interface IMedia {
  id: number | null;
  spaceId: number | null;
  name: string;
}

export type MediaSelectorType = MediaSelector<IMedia>;

export class MediaSelector<MediaType extends IMedia> {
  protected baseUrl: string | null = null;
  protected medias: MediaType[] | null = null;

  public hydrate(json: string) {
    const { baseUrl, medias } = JSON.parse(json);
    this.baseUrl = baseUrl
    this.medias = medias;
  }
  public dehydrate(): string {
    return JSON.stringify({
      baseUrl: this.baseUrl,
      medias: this.medias,
    });
  }

  public getMedia(input: MediaSelectorInput): MediaSelectorOutput {
    let result = (this.medias || []).find(m => m.name === input.mediaName)
    if (result)
      return {
        media: result,
        url: `${this.baseUrl || ''}/${result.spaceId || "root"}/media/${result.name}`
      }
    else throw new Error(`No media file "${input.mediaName}" found`);
  }
}
