import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import i18next from 'i18next';

interface SimpleDialogProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  open: boolean;
  setOpen: (open: boolean) => void;
  onOk?: () => void;
  keepOpenOnConfirm?: boolean;
  customActions?: React.ReactNode;
  [x: string]: any;
}

export default function SimpleDialog(props: SimpleDialogProps) {
  const { title, children, open, setOpen, onOk, keepOpenOnConfirm, ...rest } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog" {...rest}>
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {props.customActions}
        {!props.customActions && <Button
          variant="contained"
          onClick={() => {
            if (!keepOpenOnConfirm) {
              setOpen(false);
            }
            onOk && onOk();
          }}
          color="primary"
        >
          OK
        </Button>}
      </DialogActions>
    </Dialog>
  );
}
