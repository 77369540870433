import moment from 'moment';

export type PriceListInput = {
  date: Date;
  priceListId?: number | null;
};

export interface IPriceList {
  id: number;
  name: string;
  refCode: string;
  version: string;
  currency: string;
  isPricesNet: boolean;
  hotelId: number | null;
  forLister: boolean;
  forWidget: boolean;
  validity: IPriceListValidity[];
}
export interface IPriceListValidity {
  validFrom: Date | null;
  validTo: Date | null;
}

export class PriceListSelector<PriceListType extends IPriceList> {
  protected priceLists: PriceListType[] | null = null;
  protected _cachedValidity: IPriceListValidity | null = null;
  protected _cachedValidityRanges: Array<IPriceListValidity> | null = null

  public hydrate(json: string) {
    const { priceLists } = JSON.parse(json);
    this.priceLists = priceLists;
  }
  public dehydrate(): string {
    return JSON.stringify({
      priceLists: this.priceLists,
    });
  }

  public getPriceListIds() {
    return this.priceLists?.map(pl => pl.id) || [];
  }
  public getPriceLists() {
    return this.priceLists || [];
  }

  public getValidity(): IPriceListValidity {
    if (!this._cachedValidity) {
      let validFrom: Date | null = null;
      let validTo: Date | null = null;

      for (const priceList of this.priceLists || []) {
        if (priceList.validity && priceList.validity.length > 0) {
          for (const validity of priceList.validity) {
            if (!validity.validFrom) {
              validFrom = null
            } else if (validity.validFrom && validFrom) {
              if (moment(validFrom).isAfter(moment(validity.validFrom))) {
                validFrom = validity.validFrom;
              }
            } else if (validity.validFrom) {
              validFrom = validity.validFrom;
            }
            if (!validity.validTo) {
              validTo = null
            } else if (validity.validTo && validTo) {
              if (moment(validTo).isBefore(moment(validity.validTo))) {
                validTo = validity.validTo;
              }
            } else if (validity.validTo) {
              validTo = validity.validTo
            }
          }
        } else {
          validFrom = null;
          validTo = null;
          break;
        }
      }
      this._cachedValidity = {
        validFrom: validFrom ? moment(validFrom).startOf('day').toDate() : null,
        validTo: validTo ? moment(validTo).endOf('day').toDate() : null
      }
    }
    return this._cachedValidity;
  }

  public getValidityRanges(): Array<IPriceListValidity> {
    if (!this._cachedValidityRanges) {
      const validityRanges: Array<IPriceListValidity> = [] 
      for (const priceList of this.priceLists || []) {
        if (priceList.validity && priceList.validity.length > 0) {
          for (const validity of priceList.validity) {
            validityRanges.push(validity)
          }
        }
      }
      this._cachedValidityRanges = validityRanges
      return validityRanges
    }
    return this._cachedValidityRanges
  }

  public inValidRange(date: Date): Boolean {
    const validRanges = this.getValidityRanges()
    if (validRanges.length === 0) {
      return true
    }
    for (const validRange of validRanges) {
      if (validRange.validFrom && validRange.validTo) {
        if (moment(date).startOf('day').isSameOrAfter(moment(validRange.validFrom)) && moment(date).isSameOrBefore(validRange.validTo)) {
          return true
        }
      }
      if (validRange.validFrom && !validRange.validTo) {
        if (moment(date).startOf('day').isSameOrAfter(moment(validRange.validFrom))) {
          return true
        }
      }
      if (!validRange.validFrom && validRange.validTo) {
        if (moment(date).startOf('day').isSameOrBefore(moment(validRange.validTo))) {
          return true
        }
      }
      if (validRanges.length === 1 && !validRange.validFrom && !validRange.validTo) {
        return true
      }
    }
    return false
  }

  public rangeInValidRange(dateFrom: Date, dateTo: Date): Boolean {
    for (let m = moment(dateFrom); m.isSameOrBefore(dateTo); m.add(1, 'days')) {
      if (!this.inValidRange(m.toDate())) {
        return false
      }
    }
    return true
  }

  public findPriceList(input: PriceListInput): PriceListType | null {
    if (input.priceListId) {
      return this.priceLists?.find(s => s.id === input.priceListId) || null;
    }

    if (this.priceLists && this.priceLists.length > 0) {
      const dateMom = moment(input.date);

      const matchingLists = this.priceLists.filter(pl => {
        if (pl.validity.length === 0) return true;

        const matchingValidity = pl.validity.find(v => {
          if (v.validFrom && moment(v.validFrom).startOf('day').isAfter(dateMom)) return false;
          if (v.validTo && moment(v.validTo).endOf('day').isBefore(dateMom)) return false;
          return true;
        });
        if (matchingValidity) return true;
        else return false;
      });
      if (matchingLists.length > 0) {
        const matchingHotelList = matchingLists.find(pl => pl.hotelId) || null;
        if (matchingHotelList) return matchingHotelList;

        const matchingDefaultList = matchingLists.find(pl => !pl.hotelId);
        if (matchingDefaultList) return matchingDefaultList;
      }
    }
    return null;
  }
}
