import React, { useEffect } from 'react';
import _, { assign } from 'lodash';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlusIcon from '@mui/icons-material/Add';
import { CircularProgress, Alert, Grid, Button, IconButton, TextField, Typography, Divider, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import i18next, { t, use } from 'i18next';
import { Helmet } from 'react-helmet';

import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import SimpleTable from 'components/table/SimpleTable';
import CustomTabs from 'components/Tabs';
import { EOfferSource, EOfferStatus, EOfferVersionStatus, EOfferVersionType, ETM_TaskPriority, ETM_TaskStatus, ListWlOfferVersionsQuery, TM_TaskOutput } from '__generated__/graphql';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import SimpleAccordion from 'components/SimpleAccordion';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { FormatDateTime, FormatDateRange, FormatDate } from 'components/DateTime';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/ForwardToInbox';
import { filterSelector } from 'helper/filter';
import { RedirectError } from 'pages/error';
import { CREATE_TASK, LIST_TASK_CATEGORIES, LIST_TASK_HOTELS, LIST_TASK_PUBLIC_CLIENTS, LIST_TASK_PUBLIC_COMPANIES, LIST_TASK_SUBJECTS, LIST_TASK_USERS, LIST_TASKS, UPDATE_TASK, UPDATE_TASK_FILTER, VIEW_TASK_FILTER, LIST_TASK_CATEGORY_GROUPS, LIST_TASK_BOOKINGS, ADD_TASK_COMMENT } from './gql';
import SimpleDialog from 'components/dialogs/Dialog';
import yup from 'validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInputDate } from 'components/form/FormInputDate';
import { FormInputText } from 'components/form/FormInputText';
import { FormInputDropdown, SimpleDropdown } from 'components/form/FormInputDropdown';
import { Check, Edit, Filter, Filter1, FilterAlt, Settings, Star, Warning } from '@mui/icons-material';
import { FormInputMultiCheckbox } from 'components/form/FormInputMultiCheckbox';
import { time } from 'console';
import { userSelector } from 'helper/security';
import moment from 'moment';

const validationSchema = yup.object().shape({
  description: yup.string(),
  dueDate: yup.date().required(),
  status: yup.string().required(),
  categoryId: yup.number().required(),
  subjectId: yup.number().required(),
  assigneeId: yup.number().nullable(),
  hotelId: yup.number().nullable(),
  offerId: yup.number().nullable(),
  publicClientId: yup.number().nullable(),
  publicCompanyId: yup.number().nullable(),
});

const validationSchemaFilter = yup.object().shape({
  status: yup.mixed().required(),
  assigneeId: yup.number().nullable(),
  categoryIds: yup.array().of(yup.number()),
  hotelId: yup.number().nullable(),
  publicClientId: yup.number().nullable(),
  publicCompanyId: yup.number().nullable(),
  dueFrom: yup.date().nullable(),
  dueTo: yup.date().nullable(),
});

const validationSchemaComment = yup.object().shape({
  comment: yup.string().required(),
});

type TaskFormType = yup.InferType<typeof validationSchema>;
type TaskFilterFormType = yup.InferType<typeof validationSchemaFilter>;
type TaskCommentFormType = yup.InferType<typeof validationSchemaComment>;

export default function TasksBoard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = filterSelector();

  const [showTaskDialog, setShowTaskDialog] = React.useState(false);
  const [showFilterDialog, setShowFilterDialog] = React.useState(false);
  const [taskToEdit, setTaskToEdit] = React.useState<TM_TaskOutput | null>(null);

  const [quickfilterAssignee, setQuickfilterAssignee] = React.useState<string | null | number>(null);

  const [createTask] = useMutation(CREATE_TASK)
  const [updateTask] = useMutation(UPDATE_TASK)
  const [updateTaskFilter] = useMutation(UPDATE_TASK_FILTER)
  const [addTaskComment] = useMutation(ADD_TASK_COMMENT)

  const { data, loading, error, refetch } = useQuery(LIST_TASKS)
  const { data: taskCategoriesData, loading: taskCategoriesLoading, error: taskCategoriesError } = useQuery(LIST_TASK_CATEGORIES)
  const { data: taskCategoryGroupsData, loading: taskCategoryGroupsLoading, error: taskCategoryGroupsError } = useQuery(LIST_TASK_CATEGORY_GROUPS)
  const { data: taskSubjectsData, loading: taskSubjectsLoading, error: taskSubjectsError } = useQuery(LIST_TASK_SUBJECTS)
  const { data: taskUsersData, loading: taskUsersLoading, error: taskUsersError } = useQuery(LIST_TASK_USERS)
  const { data: taskHotelsData, loading: taskHotelsLoading, error: taskHotelsError } = useQuery(LIST_TASK_HOTELS)
  const { data: taskBookingsData, loading: taskBookingsLoading, error: taskBookingsError } = useQuery(LIST_TASK_BOOKINGS)
  const { data: taskPublicClientsData, loading: taskPublicClientsLoading, error: taskPublicClientsError } = useQuery(LIST_TASK_PUBLIC_CLIENTS)
  const { data: taskPublicCompaniesData, loading: taskPublicCompaniesLoading, error: taskPublicCompaniesError } = useQuery(LIST_TASK_PUBLIC_COMPANIES)
  const { data: taskFilterData, loading: taskFilterLoading, error: taskFilterError } = useQuery(VIEW_TASK_FILTER)

  const _createDefaultValues = {
      description: '',
      dueDate: new Date(),
      status: ETM_TaskStatus.OPEN,
      categoryId: null,
      subjectId: null,
      assigneeId: null,
      hotelId: null,
      bookingId: null,
      publicClientId: null,
      publicCompanyId: null,
  }

  const user = userSelector();

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    setValue,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: _createDefaultValues,
  });

  const {
    handleSubmit: handleSubmitFilter,
    control: controlFilter,
    trigger: triggerFilter,
    reset: resetFilter,
    watch: watchFilter,
    setValue: setValueFilter,
    formState: { errors: validationErrorsFilter, isDirty: isDirtyFilter, isValidating: isValidatingFilter, isSubmitting: isSubmittingFilter },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchemaFilter),
    context: { client: useApolloClient() },
    defaultValues: { status: ETM_TaskStatus.OPEN, assigneeId: null, categoryIds: [], hotelId: null, publicClientId: null, publicCompanyId: null },
  });

  const {
    handleSubmit: handleSubmitComment,
    control: controlComment,
    trigger: triggerComment,
    reset: resetComment,
    watch: watchComment,
    setValue: setValueComment,
    formState: { errors: validationErrorsComment, isDirty: isDirtyComment, isValidating: isValidatingComment, isSubmitting: isSubmittingComment },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchemaComment),
    context: { client: useApolloClient() },
    defaultValues: { comment: '' },
  });

  useEffect(() => {
    if (watch('publicCompanyId') === null) {
      setValue('publicClientId', null);
    }
    if (taskPublicClientsData?.listTaskPublicClients.find(c => c.id === watch('publicClientId'))?.publicCompanyId !== watch('publicCompanyId')) {
      setValue('publicClientId', null);
    }
  }, [watch('publicCompanyId')])

  useEffect(() => {
    if (watchFilter('publicCompanyId') === null) {
      setValueFilter('publicClientId', null);
    }
    if (taskPublicClientsData?.listTaskPublicClients.find(c => c.id === watchFilter('publicClientId'))?.publicCompanyId !== watchFilter('publicCompanyId')) {
      setValueFilter('publicClientId', null);
    }
  }, [watchFilter('publicCompanyId')])

  useEffect(() => {
    if (watch('categoryId') === null) {
      setValue('subjectId', null);
    }
    if (taskSubjectsData?.listTaskSubjects.find(s => s.id === watch('subjectId'))?.categoryId !== watch('categoryId')) {
      setValue('subjectId', null);
    }
  }, [watch('categoryId')])

  useEffect(() => {
      const assigneeId = (taskFilterData?.viewTaskFilter?.assignees || []).length > 0 ? (taskFilterData?.viewTaskFilter?.assignees || [])[0].id : null;
      resetFilter({ 
        status: taskFilterData?.viewTaskFilter?.status || ETM_TaskStatus.OPEN, 
        assigneeId: assigneeId, 
        categoryIds: taskFilterData?.viewTaskFilter?.categories.map(c => c.id), 
        hotelId: taskFilterData?.viewTaskFilter?.hotels.map(c => c.id)[0] || null,
        publicClientId: taskFilterData?.viewTaskFilter?.publicClients.map(c => c.id)[0] || null,
        publicCompanyId: taskFilterData?.viewTaskFilter?.publicCompanies.map(c => c.id)[0] || null,
        dueFrom: taskFilterData?.viewTaskFilter?.dueFrom,
        dueTo: taskFilterData?.viewTaskFilter?.dueTo,
      });
  }, [taskFilterData])

  const _renderFilterDialog = () => {
    return <SimpleDialog maxWidth="md" title="Filter" open={showFilterDialog} setOpen={setShowFilterDialog} customActions={<Grid container spacing={2}>
      <Grid item xs={12} sx={{ justifyContent: 'end' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setShowFilterDialog(false);
          }}
        >
          Abbrechen
        </Button>
        <Button variant="contained" color="primary" sx={{ marginLeft: 2 }} onClick={async () => {
          try {
            await updateTaskFilter({
              variables: {
                input: {
                  status: watchFilter('status') as ETM_TaskStatus,
                  assigneeIds: !_.isNil(watchFilter('assigneeId')) ? [watchFilter('assigneeId') as number] : [],
                  categoryIds: _.isArray(watchFilter('categoryIds')) ? watchFilter('categoryIds') as number[] : [],
                  hotelIds: !_.isNil(watchFilter('hotelId')) ? [watchFilter('hotelId') as number] : [],
                  publicClientIds: !_.isNil(watchFilter('publicClientId')) ? [watchFilter('publicClientId') as number] : [],
                  publicCompanyIds: !_.isNil(watchFilter('publicCompanyId')) ? [watchFilter('publicCompanyId') as number] : [],
                  dueFrom: watchFilter('dueFrom'),
                  dueTo: watchFilter('dueTo'),
                }
              }, refetchQueries: [{ query: VIEW_TASK_FILTER }, { query: LIST_TASKS }]
            })
            dispatchMessage(dispatch, 'Filter wurde erfolgreich gespeichert');
            setShowFilterDialog(false);
          } catch (err) {
            dispatchException(dispatch, err);
          }}}>
          Filter anwenden
        </Button>
      </Grid>
    </Grid>}>
      <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormInputDropdown control={controlFilter} label="Status" name="status" options={[
          { value: ETM_TaskStatus.OPEN, label: 'Offen' },
          { value: ETM_TaskStatus.DONE, label: 'Erledigt' },
        ]} />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormInputDate timePicker control={controlFilter} label="Fällig ab" name="dueFrom" />
        </Grid>
        <Grid item xs={6}>
          <FormInputDate timePicker control={controlFilter} label="Fällig bis" name="dueTo" />
        </Grid>
        <Grid item xs={6}>
          <FormInputDropdown enableFilter control={controlFilter} label="Zugewiesen an" name="assigneeId" options={[{ value: null, label: '<Alle>' }, ...(taskUsersData?.listTaskUsers || []).map((user) => ({ value: user.id, label: user.name }))]} />
        </Grid>
        <Grid item xs={6}>
          <FormInputDropdown enableFilter control={controlFilter} label="Hotel" name="hotelId" options={[{ value: null, label: '<Alle>' }, ...(taskHotelsData?.listTaskHotels || []).map((hotel) => ({ value: hotel.id, label: hotel.businessName }))]} />
        </Grid>
        <Grid item xs={6}>
          <FormInputDropdown enableFilter control={controlFilter} label="Firma" name="publicCompanyId" options={[{ value: null, label: '<Alle>' }, ...(taskPublicCompaniesData?.listTaskPublicCompanies || []).map((company) => ({ value: company.id, label: company.name }))]} />
        </Grid>
        <Grid item xs={6}>
          <FormInputDropdown enableFilter disabled={_.isNil(watchFilter('publicCompanyId'))} control={controlFilter} label="Ansprechperson" name="publicClientId" options={[{ value: null, label: '<Alle>' }, ...(taskPublicClientsData?.listTaskPublicClients || []).filter(client => client.publicCompanyId === watchFilter('publicCompanyId')).map((client) => ({ value: client.id, label: client.firstname + ' ' + client.lastname }))]} />
        </Grid>
        {(taskCategoryGroupsData?.listTaskCategoryGroups || []).map((categoryGroup) => (
          <Grid item xs={6}>
            <FormInputMultiCheckbox control={controlFilter} label={categoryGroup.name} name="categoryIds" options={(taskCategoriesData?.listTaskCategories || []).filter(c => c.groupId === categoryGroup.id).map(c => ({ value: c.id, label: c.name }))} />
          </Grid>
        ))}
        </Grid>
        </SimpleDialog>
  }

  const _renderTaskDialog = () => {
    return <SimpleDialog maxWidth="md" title={taskToEdit ? 'Task bearbeiten' : 'Task erstellen'} open={showTaskDialog} setOpen={setShowTaskDialog} customActions={<Grid container spacing={2}>
      <Grid item xs={6} sx={{ justifyContent: 'end' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setShowTaskDialog(false);
          }}
        >
          Abbrechen
        </Button>
        <Button variant="contained" color="primary" sx={{ marginLeft: 2 }} onClick={async () => {
          const isValid = await trigger();
          if (!isValid) {
            return;
          }
          try {
            if (taskToEdit) {
              await updateTask({
                variables: {
                  id: taskToEdit.id,
                  input: {
                    description: watch('description'),
                    dueDate: watch('dueDate'),
                    status: watch('status'),
                    categoryId: watch('categoryId') || 0,
                    subjectId: watch('subjectId') || 0,
                    priority: ETM_TaskPriority.NORMAL,
                    assigneeId: watch('assigneeId') || null,
                    hotelId: watch('hotelId') || null,
                    bookingId: watch('bookingId') || null,
                    publicClientId: watch('publicClientId') || null,
                    publicCompanyId: watch('publicCompanyId') || null,
                  }
                }, refetchQueries: [{ query: LIST_TASKS }]
              })
            } else {
              await createTask({
                variables: {
                  input: {
                    description: watch('description'),
                    dueDate: watch('dueDate'),
                    status: watch('status'),
                    categoryId: watch('categoryId') || 0,
                    subjectId: watch('subjectId') || 0,
                    priority: ETM_TaskPriority.NORMAL,
                    assigneeId: watch('assigneeId') || null,
                    hotelId: watch('hotelId') || null,
                    bookingId: watch('bookingId') || null,
                    publicClientId: watch('publicClientId') || null,
                    publicCompanyId: watch('publicCompanyId') || null,
                  }
                }, refetchQueries: [{ query: LIST_TASKS }]
              })
            }
            dispatchMessage(dispatch, 'Task wurde erfolgreich gespeichert');
            setShowTaskDialog(false);
          } catch (err) {
            dispatchException(dispatch, err);
          }}}>
          Speichern
        </Button>
      </Grid>
        <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
        {taskToEdit && <Button variant="contained" color="primary" sx={{ marginLeft: 2 }} onClick={async () => {
          try {
              await updateTask({
                variables: {
                  id: taskToEdit.id,
                  input: {
                    description: watch('description'),
                    dueDate: watch('dueDate'),
                    status: ETM_TaskStatus.DONE,
                    categoryId: watch('categoryId') || 0,
                    subjectId: watch('subjectId') || 0,
                    priority: ETM_TaskPriority.NORMAL,
                    assigneeId: watch('assigneeId') || null,
                    hotelId: watch('hotelId') || null,
                    bookingId: watch('bookingId') || null,
                    publicClientId: watch('publicClientId') || null,
                    publicCompanyId: watch('publicCompanyId') || null,
                  }
                }, refetchQueries: [{ query: LIST_TASKS }]
              })
            dispatchMessage(dispatch, 'Task wurde auf erledigt gesetzt');
            setShowTaskDialog(false);
          } catch (err) {
            dispatchException(dispatch, err);
          }}}>
          <Check /> Erledigt
        </Button>}
        </Grid>
    </Grid>}>
      <Grid container spacing={2}>
      <Grid item xs={6}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
          <FormInputDropdown
            control={control}
            label="Zuweisung"
            name="assigneeId"
            options={[{ value: null, label: 'keine/r' }, ...(taskUsersData?.listTaskUsers || []).map((user) => ({ value: user.id, label: user.name }))]}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputDate
            control={control}
            label="Fälligkeitsdatum"
            name="dueDate"
            required
          />
        </Grid>
        <Grid item xs={12}>
        <FormInputDropdown
          enableFilter
            control={control}
            disabled={(!_.isNil(taskToEdit) && _.isNil(taskToEdit?.createdBy))}
            label="Kategorie"
            name="categoryId"
            options={(taskCategoriesData?.listTaskCategories || []).map((category) => ({ value: category.id, label: category.group?.name + ' / ' + category.name }))}
            required
          />
          </Grid>
          <Grid item xs={12}>
          <FormInputDropdown
            enableFilter
            control={control}
            disabled={!watch('categoryId') || (!_.isNil(taskToEdit) && _.isNil(taskToEdit?.createdBy))}
            label="Betreff"
            name="subjectId"
            options={(taskSubjectsData?.listTaskSubjects || []).filter(s => s.categoryId === watch("categoryId")).map((subject) => ({ value: subject.id, label: subject.name }))}
            required
          />
          </Grid>
          <Grid item xs={12}>
          <FormInputText
            textFieldProps={{ multiline: true, rows: 4 }}
            control={control}
            disabled={(!_.isNil(taskToEdit) && _.isNil(taskToEdit?.createdBy))}
            label="Beschreibung"
            name="description"
            required
          />
        </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
          <FormInputDropdown
            enableFilter
            control={control}
            disabled={(!_.isNil(taskToEdit) && _.isNil(taskToEdit?.createdBy))}
            label="Hotel"
            name="hotelId"
            options={[{ value: null, label: '<keines>' }, ...(taskHotelsData?.listTaskHotels || []).map((hotel) => ({ value: hotel.id, label: hotel.businessName }))]}
          />
          </Grid>
          <Grid item xs={12}>
          <FormInputDropdown
            enableFilter
            control={control}
            disabled={(!_.isNil(taskToEdit) && _.isNil(taskToEdit?.createdBy))}
            label="Buchung"
            name="bookingId"
            options={[{ value: null, label: '<keine>'}, ...(taskBookingsData?.listTaskBookings || []).map((booking) => ({ value: booking.id, label: booking.title + ' (' + booking.refCode + ')' }))]}
                      />
          </Grid>
          <Grid item xs={12}>
          <FormInputDropdown
            enableFilter
            control={control}
            disabled={(!_.isNil(taskToEdit) && _.isNil(taskToEdit?.createdBy))}
            label="Firma"
            name="publicCompanyId"
            options={[{ value: null, label: '<keine>' }, ...(taskPublicCompaniesData?.listTaskPublicCompanies || []).map((company) => ({ value: company.id, label: company.name }))]}
          />
        </Grid>
          <Grid item xs={12}>
          <FormInputDropdown
            enableFilter
            control={control}
            disabled={!watch('publicCompanyId') || (!_.isNil(taskToEdit) && _.isNil(taskToEdit?.createdBy))}
            label="Ansprechperson"
            name="publicClientId"
            options={[{ value: null, label: '<keine>' }, ...(taskPublicClientsData?.listTaskPublicClients || []).filter(client => client.publicCompanyId === watch('publicCompanyId')).map((client) => ({ value: client.id, label: client.firstname + ' ' + client.lastname }))]}
          />
        </Grid>
        </Grid>
        </Grid>
        {taskToEdit && <Grid item xs={12}>
          <Divider sx={{ mb: 1 }} />
          <SimpleAccordion header={`Kommentare (${taskToEdit.comments.length})`}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <SimpleTable headers={['Kommentar', 'Erstellt am', 'Erstellt von']} rows={taskToEdit.comments.map((comment) => [comment.comment, <FormatDateTime date={comment.createdAt} />, comment.user?.name])} />
              </Grid>
            <Grid item xs={12}>
            <FormInputText control={controlComment} label="Kommentar" name="comment" />
            <Button variant="contained" color="primary" onClick={async () => {
              const isValid = await triggerComment();
              if (!isValid) {
                return;
              }
              try {
                await addTaskComment({
                  variables: {
                    taskId: taskToEdit.id,
                    comment: watchComment('comment')
                  }
                })
                const r = await refetch();
                setTaskToEdit(r.data.listTasks.find(t => t.id === taskToEdit.id) as TM_TaskOutput || null);
                dispatchMessage(dispatch, 'Kommentar wurde erfolgreich gespeichert');
                resetComment();
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }
            }>
              Kommentar hinzufügen
            </Button>
            </Grid>
          </Grid>
            </SimpleAccordion>
        </Grid>}
      </Grid>
    </SimpleDialog>
  }

  return (
    <>
      {_renderTaskDialog()}
      {_renderFilterDialog()}
      <Helmet>
        <title>{i18next.t(`tasks-page-title`)}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            sx={{ marginBottom: 2 }}
            onClick={async () => {
              reset(_createDefaultValues);
              setTaskToEdit(null);
              setShowTaskDialog(true);
            }}
          >
            <PlusIcon /> Neuen Task erstellen
          </Button>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          <Grid item xs={12}>
          <SimpleDropdown value={quickfilterAssignee} name="quickfilterAssignee" label='Zugewiesen an' onChange={async (e) => {
            setQuickfilterAssignee(e.target.value);
            let assigneeIds: number[] = []
            if (e.target.value === 'mine') {
              assigneeIds = [user?.id!]
            }
            if (_.isNumber(e.target.value)) {
              assigneeIds = [e.target.value]
            }
            if (e.target.value === 'all') {
              assigneeIds = []
            }
            try {
              await updateTaskFilter({
                variables: {
                  input: {
                    status: watchFilter('status') as ETM_TaskStatus,
                    assigneeIds: assigneeIds,
                    categoryIds: _.isArray(watchFilter('categoryIds')) ? watchFilter('categoryIds') as number[] : [],
                    hotelIds: !_.isNil(watchFilter('hotelId')) ? [watchFilter('hotelId') as number] : [],
                    publicClientIds: !_.isNil(watchFilter('publicClientId')) ? [watchFilter('publicClientId') as number] : [],
                    publicCompanyIds: !_.isNil(watchFilter('publicCompanyId')) ? [watchFilter('publicCompanyId') as number] : [],
                    dueFrom: watchFilter('dueFrom'),
                    dueTo: watchFilter('dueTo'),
                  }
                }, refetchQueries: [{ query: VIEW_TASK_FILTER }, { query: LIST_TASKS }]
              })
              dispatchMessage(dispatch, 'Filter wurde erfolgreich gespeichert');
              setShowFilterDialog(false);
            } catch (err) {
              dispatchException(dispatch, err);
            }
          }} options={[
            { value: 'mine', label: 'Nur Meine' },
            { value: 'all', label: 'Alle' },
            { label: '', divider: true },
            ...(taskUsersData?.listTaskUsers || []).map((user) => ({ value: user.id, label: user.name }))
          ]} />
          </Grid>
          <Grid item>
          <Button
            variant="contained"
            color='secondary'
            sx={{ marginBottom: 2 }}
            onClick={async () => {
              setShowFilterDialog(true);
            }}
          >
            <FilterAlt /> Filter
          </Button>
          </Grid>
          {user?.isAdmin && <Grid item>
          <Button
            variant="contained"
            color='secondary'
            sx={{ marginBottom: 2 }}
            onClick={async () => {
              navigate('/tasks/settings');
            }}
          >
            <Settings />
          </Button>
          </Grid>}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <div style={{ marginBottom: 3, marginLeft: 1 }}>{data?.listTasks.length} Tasks</div>
        <SimpleTable
          headers={[
            'Betreff',
            'Kategorie',
            'Fällig',
            'Zugewiesen an',
            'Hotel',
            'Firma',
            'Ansprechperson',
            'Buchung',
            ''
          ]}
          rows={
            data?.listTasks.map((task) => [
              task.subject?.name,
              task.category?.name,
              moment(task.dueDate).isBefore(new Date()) ? <div style={{ color: '#900', fontWeight: 'bold' }}><FormatDateTime date={task.dueDate} /></div> : <FormatDateTime date={task.dueDate} />,
              task.assignee ? task.assignee?.name : '-',
              task.hotel ? <Link to={`/settings/hotels/${task.hotelId}`}>{task.hotel?.businessName}</Link> : '-',
              task.publicCompany ? <Link to={`/clients/companies/${task.publicCompany.id}`}><div style={{ display: 'flex', alignItems: 'center' }}>{task.publicCompany.isPremium ? <Star fontSize="small" style={{ marginRight: 1 }} /> : ''}{task.publicCompany?.name}</div></Link> : '-',
              task.publicClient ? <Link to={`/clients/clients/${task.publicClient.id}`}>{task.publicClient?.firstname} {task.publicClient?.lastname}</Link> : '-',
              task.booking ? <Link to={`/bookings/${task.booking.id}`}>{task.booking?.title} ({task.booking?.refCode})</Link> : '-',
              <IconButton onClick={() => {
                setTaskToEdit(task as TM_TaskOutput);
                console.log(task);
                reset(task as any);
                setShowTaskDialog(true);
              }}>
                <Edit />
              </IconButton>
            ]) || []
          }
        />
        </Grid>
      </Grid>
    </>
  );
}
