import React, { useEffect } from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import TasksSettingsGroups from './tasks_groups';
import TasksSettingsCategories from './tasks_categories';
import TasksSettingsSubjects from './tasks_subjects';
import TasksCategoryUpdate, { TasksCategoryCreate } from './tasks_category';
import TasksGroupUpdate, { TasksGroupCreate } from './tasks_group';
import TasksSubjectUpdate, { TasksSubjectCreate } from './tasks_subject';

export default function TasksSettingsIndex() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/tasks/settings') {
      navigate('/tasks/settings/groups');
    }
  }, [location.pathname]);

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Tabs
            value={
              location.pathname === '/tasks/settings' ? '/tasks/settings/groups' : location.pathname.split('/').slice(0, 4).join('/')
            }
          >
            <Tab
              value='/tasks/settings/groups'
              label="Bereiche"
              component={Link}
              to='/tasks/settings/groups' 
            />
            <Tab
              value='/tasks/settings/categories'
              label="Kategorien"
              component={Link}
              to='/tasks/settings/categories' 
            />
            <Tab
              value='/tasks/settings/subjects'
              label="Betreffe"
              component={Link}
              to='/tasks/settings/subjects'
            />
          </Tabs>

          <Box p={2}>
            
          <Routes>
              <Route path="groups" index element={<TasksSettingsGroups />} />
              <Route path="groups/_create" element={<TasksGroupCreate />} />
              <Route path='groups/:id' Component={() => <TasksGroupUpdate id={parseInt(useParams().id!)} />} />
              <Route path="categories" element={<TasksSettingsCategories />} />
              <Route path="categories/_create" element={<TasksCategoryCreate />} />
              <Route path='categories/:id' Component={() => <TasksCategoryUpdate id={parseInt(useParams().id!)} />} />
              <Route path="subjects" element={<TasksSettingsSubjects />} />
              <Route path='subjects/_create' Component={() => <TasksSubjectCreate />} />
              <Route path='subjects/:id' Component={() => <TasksSubjectUpdate id={parseInt(useParams().id!)} />} />
            </Routes>
            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}