import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlusIcon from '@mui/icons-material/Add';
import { CircularProgress, Alert, Grid, Button, IconButton, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import { useMutation, useQuery } from '@apollo/client';
import {
  OFFERVERSIONS_LIST_QUERY,
  REFETCH_OFFER_QUERIES,
  EVICT_OFFER_QUERIES,
  OFFERVERSION_DELETE_MUTATION,
  OFFER_SEND_REMINDER_CLIENT_MUTATION
} from './gql';
import SimpleTable from 'components/table/SimpleTable';
import CustomTabs from 'components/Tabs';
import { EOfferSource, EOfferStatus, EOfferVersionStatus, EOfferVersionType, ListWlOfferVersionsQuery } from '__generated__/graphql';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import SimpleAccordion from 'components/SimpleAccordion';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { FormatDateTime, FormatDateRange } from 'components/DateTime';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/ForwardToInbox';
import { filterSelector } from 'helper/filter';
import { RedirectError } from 'pages/error';
import { userSelector } from 'helper/security';

export default function OffersList(props: { offerSource: EOfferSource }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const _renderOfferVersionsTable = (
    title: string,
    offers: ListWlOfferVersionsQuery['listWlOfferVersions'],
    linkPrefix: string,
    setDeleteOfferVersionId?: (id: number) => void,
    setShowDeleteDialog?: (show: boolean) => void,
    sendOfferReminderEmailClient?: any,
  ) => {
    return (
      <SimpleAccordion header={title} autoOpen={true}>
        <SimpleTable
          headers={[
            i18next.t('offers-list-header-createdat'),
            i18next.t('offers-list-header-refcode'),
            i18next.t('offers-list-header-hotel'),
            i18next.t('offers-list-header-from-to'),
            i18next.t('offers-list-header-client'),
            i18next.t('offers-list-header-status'),
            i18next.t('offers-list-header-updatedat'),
            '',
            '',
            ...(sendOfferReminderEmailClient ? [''] : []),
          ]}
          rows={
            offers.map((row, index) => [
              <FormatDateTime date={row.createdAt} />,
              <Link to={`${linkPrefix}/${row.offer.id}/${row.id}`}>{row.offer.refCode}</Link>,
              <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
              <FormatDateRange from={row.input?.startDate} to={row.input?.endDate} />,
              row.client?.lastname + ' ' + row.client?.firstname + ' / ' + row.client?.company,
              i18next.t(`enums-EOfferVersionStatus-${row.status}`),
              <FormatDateTime date={row.updatedAt} />,
              setDeleteOfferVersionId && setShowDeleteDialog &&
                <IconButton
                  title={`${i18next.t('offers-list-delete')}`}
                  onClick={() => {
                    setDeleteOfferVersionId(row.id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ,
              <IconButton title={`${i18next.t('offers-list-show')}`} onClick={() => navigate(`${linkPrefix}/${row.offer.id}/${row.id}`)}>
                <OpenIcon />
              </IconButton>,
              <>
                {sendOfferReminderEmailClient && (
                  <IconButton
                    title={`${i18next.t('offer-reminder-email-send')}`}
                    onClick={async () => {
                      try {
                        await sendOfferReminderEmailClient({
                          variables: {
                            offerVersionId: row.id,
                          },
                        });
                        dispatchMessage(dispatch, i18next.t('offer-reminder-email-sent'));
                      } catch (error: any) {
                        dispatchException(dispatch, error);
                      }
                    }}
                  >
                    <SendIcon />
                  </IconButton>
                )}
              </>,
            ]) || []
          }
        />
      </SimpleAccordion>
    );
  };

  const queryOpenForInvoiceSemApproval = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.INVOICE],
      versionStatus: [EOfferVersionStatus.IV_WAITFORSEMAPPROVAL],
      offerStatus: [EOfferStatus.INVOICE]
    },
    skip: props.offerSource !== EOfferSource.LISTER || !user.isSeminargo
  });

  const queryOpenForInvoice = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.INVOICE],
      versionStatus: [EOfferVersionStatus.IV_WAITFORHOTEL],
      offerStatus: [EOfferStatus.INVOICE]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenForInvoiceApproval = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.INVOICE],
      versionStatus: [EOfferVersionStatus.IV_WAITFORSEMAPPROVAL, EOfferVersionStatus.IV_WAITFORCLIENTAPPROVAL],
      offerStatus: [EOfferStatus.INVOICE]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenToApprove = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER],
      versionStatus: [EOfferVersionStatus.WAITFORHOTELAPPROVAL],
      offerStatus: [EOfferStatus.CLIENTVERIFICATION, EOfferStatus.PENDING]
    },
  });

  const queryOpenToReserve = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER],
      versionStatus: [EOfferVersionStatus.WAITFORHOTELRESERVATION],
      offerStatus: [EOfferStatus.CLIENTVERIFICATION, EOfferStatus.PENDING]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenToApproveCR = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.EDITING],
      versionStatus: [EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL],
      offerStatus: [EOfferStatus.APPROVED]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenWaitForClient = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER],
      versionStatus: [EOfferVersionStatus.WAITFORCLIENTAPPROVAL],
      offerStatus: [EOfferStatus.CLIENTVERIFICATION, EOfferStatus.PENDING]
    },
  });

  const queryApproved = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.APPROVED],
      offerStatus: [EOfferStatus.APPROVED, EOfferStatus.ACTIVE]
    },
  });

  const queryRejected = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER, EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.REJECTED_CLIENT, EOfferVersionStatus.REJECTED_HOTEL],
      offerStatus: [EOfferStatus.REJECTED]
    },
  });

  const queryActive = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.APPROVED],
      offerStatus: [EOfferStatus.ACTIVE]
    },
  });

  const queryClosed = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER, EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.APPROVED],
      offerStatus: [EOfferStatus.CLOSED]
    },
  });

  const queryDeleted = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER, EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.DELETED],
      offerStatus: [EOfferStatus.DELETED]
    },
  });
  const queries = [queryOpenForInvoiceSemApproval, queryOpenForInvoice, queryOpenForInvoiceApproval, queryOpenToApprove, queryOpenToReserve, queryOpenToApproveCR, queryOpenWaitForClient, queryApproved, queryRejected, queryActive, queryClosed, queryDeleted]

  const [deleteOfferVersion] = useMutation(OFFERVERSION_DELETE_MUTATION);
  const [sendOfferReminderEmailClient] = useMutation(OFFER_SEND_REMINDER_CLIENT_MUTATION);

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [deleteOfferVersionId, setDeleteOfferVersionId] = React.useState<number | null>(null);
  const [deleteReason, setDeleteReason] = React.useState('');

  const loading = queries.find(q => q.loading)
  const error = queries.find(q => q.error) ? queries.find(q => q.error)!.error : null

  if (loading) return <CircularProgress />;
  else if (error) return <RedirectError err={error} />;

  return (
    <>
      <Helmet>
        <title>{i18next.t(`offers-list-page-title-${props.offerSource}`)}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            sx={{ marginBottom: 2 }}
            onClick={async () => {
              if (props.offerSource === EOfferSource.WIDGET) navigate('/offers/_create_widget');
              if (props.offerSource === EOfferSource.LISTER) navigate('/offers/_create_lister');
            }}
          >
            <PlusIcon /> Neues Angebot erstellen
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            sx={{ marginBottom: 2 }}
            onClick={() => {
              queries.forEach(q => q.refetch())
            }}
          >
            <RefreshIcon /> Aktualisieren
          </Button>
        </Grid>
      </Grid>
      <CustomTabs
        headers={[
          i18next.t('offers-list-open'),
          i18next.t('offers-list-approved'),
          i18next.t('offers-list-rejected'),
          i18next.t('offers-list-active'),
          i18next.t('offers-list-closed'),
          i18next.t('offers-list-deleted'),
        ]}
        tabs={[
          <Grid container spacing={2}>
            {props.offerSource === EOfferSource.LISTER && user.isSeminargo &&
              <Grid item xs={12}>
                {_renderOfferVersionsTable(
                  i18next.t('offers-list-invoice-waitforsemapproval', { count: queryOpenForInvoiceSemApproval.data!.listWlOfferVersions.length }),
                  queryOpenForInvoiceSemApproval.data!.listWlOfferVersions,
                  '/invoices'
                )}
              </Grid>
            }            
            {props.offerSource === EOfferSource.LISTER &&
              <Grid item xs={12}>
                {_renderOfferVersionsTable(
                  i18next.t('offers-list-invoice-waitforhotelapproval', { count: queryOpenForInvoice.data!.listWlOfferVersions.length }),
                  queryOpenForInvoice.data!.listWlOfferVersions,
                  '/invoices'
                )}
              </Grid>
            }
            {props.offerSource === EOfferSource.LISTER &&
              <Grid item xs={12}>
                {_renderOfferVersionsTable(
                  i18next.t('offers-list-invoice-waitforinvoiceapproval', { count: queryOpenForInvoiceApproval.data!.listWlOfferVersions.length }),
                  queryOpenForInvoiceApproval.data!.listWlOfferVersions,
                  '/invoices'
                )}
              </Grid>
            }            
            <Grid item xs={12}>
              {_renderOfferVersionsTable(
                i18next.t('offers-list-open-waitforhotelapproval', { count: queryOpenToApprove.data!.listWlOfferVersions.length }),
                queryOpenToApprove.data!.listWlOfferVersions,
                '/offers',
                setDeleteOfferVersionId,
                setShowDeleteDialog,
              )}
            </Grid>
            {props.offerSource === EOfferSource.LISTER &&
              <Grid item xs={12}>
                {_renderOfferVersionsTable(
                  i18next.t('offers-list-open-waitforhotelreservation', { count: queryOpenToReserve.data!.listWlOfferVersions.length }),
                  queryOpenToReserve.data!.listWlOfferVersions,
                  '/offers',
                  setDeleteOfferVersionId,
                  setShowDeleteDialog,
                )}
              </Grid>
            }            
            {props.offerSource === EOfferSource.LISTER &&
              <Grid item xs={12}>
                {_renderOfferVersionsTable(
                  i18next.t('offers-list-open-waitforhotelcrapproval', { count: queryOpenToApproveCR.data!.listWlOfferVersions.length }),
                  queryOpenToApproveCR.data!.listWlOfferVersions,
                  '/offers',
                  setDeleteOfferVersionId,
                  setShowDeleteDialog,
                )}
              </Grid>
            }
            <Grid item xs={12}>
              {_renderOfferVersionsTable(
                i18next.t('offers-list-open-waitforclientapproval', { count: queryOpenWaitForClient.data?.listWlOfferVersions.length }),
                queryOpenWaitForClient.data!.listWlOfferVersions,
                '/offers',
                setDeleteOfferVersionId,
                setShowDeleteDialog,
                sendOfferReminderEmailClient,
              )}
            </Grid>
          </Grid>,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              '',
              '',
            ]}
            rows={
              queryApproved.data!.listWlOfferVersions.map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                <Link to={`/offers/${row.offer.id}/${row.id}`}>{row.offer.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.input?.startDate} to={row.input?.endDate} />,
                row.client?.lastname + ' ' + row.client?.firstname + ' / ' + row.client?.company,
                i18next.t(`enums-EOfferStatus-${row.offer.status}`),
                <IconButton
                  title={`${i18next.t('offers-list-delete')}`}
                  onClick={() => {
                    setDeleteOfferVersionId(row.id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.offer.id}/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              '',
              '',
            ]}
            rows={
              queryRejected.data!.listWlOfferVersions.map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                <Link to={`/offers/${row.offer.id}/${row.id}`}>{row.offer.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.input?.startDate} to={row.input?.endDate} />,
                row.client?.lastname + ' ' + row.client?.firstname + ' / ' + row.client?.company,
                i18next.t(`enums-EOfferStatus-${row.offer.status}`),
                <IconButton
                  title={`${i18next.t('offers-list-delete')}`}
                  onClick={() => {
                    setDeleteOfferVersionId(row.id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.offer.id}/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
          <SimpleTable
          headers={[
            i18next.t('offers-list-header-createdat'),
            i18next.t('offers-list-header-refcode'),
            i18next.t('offers-list-header-hotel'),
            i18next.t('offers-list-header-from-to'),
            i18next.t('offers-list-header-client'),
            i18next.t('offers-list-header-status'),
            '',
          ]}
          rows={
            queryActive.data!.listWlOfferVersions.map((row, index) => [
              <FormatDateTime date={row.createdAt} />,
              <Link to={`/offers/${row.offer.id}/${row.id}`}>{row.offer.refCode}</Link>,
              <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
              <FormatDateRange from={row.input?.startDate} to={row.input?.endDate} />,
              row.client?.lastname + ' ' + row.client?.firstname + ' / ' + row.client?.company,
              i18next.t(`enums-EOfferStatus-${row.offer.status}`),
              <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.offer.id}/${row.id}`)}>
                <OpenIcon />
              </IconButton>,
            ]) || []
          }
        />,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              '',
              '',
            ]}
            rows={
              queryClosed.data!.listWlOfferVersions.map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                <Link to={`/offers/${row.offer.id}/${row.id}`}>{row.offer.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.input?.startDate} to={row.input?.endDate} />,
                row.client?.lastname + ' ' + row.client?.firstname + ' / ' + row.client?.company,
                i18next.t(`enums-EOfferStatus-${row.offer.status}`),
                <IconButton
                  title={`${i18next.t('offers-list-delete')}`}
                  onClick={() => {
                    setDeleteOfferVersionId(row.id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.offer.id}/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              i18next.t('offers-list-header-delete-reason'),
              '',
            ]}
            rows={
              queryDeleted.data!.listWlOfferVersions.map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                <Link to={`/offers/${row.offer.id}/${row.id}`}>{row.offer.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.input?.startDate} to={row.input?.endDate} />,
                row.client?.lastname + ' ' + row.client?.firstname + ' / ' + row.client?.company,
                i18next.t(`enums-EOfferStatus-${row.offer.status}`),
                row.offer.deleteReason,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.offer.id}/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
        ]}
      />

      <ConfirmationDialog
        title={i18next.t('offerconfirm-delete-title')}
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        keepOpenOnConfirm={true}
        onConfirm={async () => {
          if (deleteReason.length === 0) {
            return;
          } else {
            try {
              await deleteOfferVersion({
                variables: {
                  offerVersionId: deleteOfferVersionId!,
                  deleteReason,
                },
                update: cache => EVICT_OFFER_QUERIES(cache),
                awaitRefetchQueries: true,
                refetchQueries: REFETCH_OFFER_QUERIES(),
              });
              setDeleteReason('');
              setShowDeleteDialog(false);
              dispatchMessage(dispatch, i18next.t('offerconfirm-delete-success'));
              queries.forEach(q => q.refetch())
            } catch (err) {
              dispatchException(dispatch, err);
            }
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {i18next.t('offerconfirm-delete-text')}
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={deleteReason.length === 0}
              fullWidth
              label={i18next.t('offerconfirm-delete-reason')}
              value={deleteReason}
              onChange={e => setDeleteReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </ConfirmationDialog>
    </>
  );
}
