import React from 'react';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { deDE } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormDateInputProps } from './FormInputProps';
import 'dayjs/locale/de';
import { DateTimePicker } from '@mui/x-date-pickers';

export const FormInputDate = ({ name, control, label, required, disabled, readOnly, datePickerProps, textFieldProps, timePicker }: FormDateInputProps) => {

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider
          localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
          dateAdapter={AdapterDayjs}
          adapterLocale="de"
        >
          {timePicker && <DateTimePicker
            label={label}
            value={value ? dayjs(value) : null}
            onChange={newValue => {
              if (newValue && newValue.isValid()) onChange(newValue.toDate());
              else onChange(null);
            }}
            readOnly={readOnly}
            disabled={disabled}
            slotProps={{
              textField: {
                helperText: error ? error.message : null,
                error: !!error,
                fullWidth: true,
                required,
                ...textFieldProps,
              },
            }}
            {...datePickerProps}
          />}
          {!timePicker && <DatePicker
            label={label}
            value={value ? dayjs(value) : null}
            onChange={newValue => {
              if (newValue && newValue.isValid()) onChange(newValue.toDate());
              else onChange(null);
            }}
            readOnly={readOnly}
            disabled={disabled}
            slotProps={{
              textField: {
                helperText: error ? error.message : null,
                error: !!error,
                fullWidth: true,
                required,
                ...textFieldProps,
              },
            }}
            {...datePickerProps}
          />}
        </LocalizationProvider>
      )}
    />
  );
};
