import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';
import i18next from 'i18next';

import Hotel, { HotelCreate } from './hotel';
import Hotels from './hotels';

export default function HotelsIndex() {
  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route path="_create" Component={() => <HotelCreate />} />
              <Route path="_create/*" Component={() => <HotelCreate />} />
              <Route index element={<Hotels />} />
              <Route path=":id" Component={() => <Hotel id={parseInt(useParams().id!)} />} />
              <Route path=":id/*" Component={() => <Hotel id={parseInt(useParams().id!)} />} />
            </Routes>
            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
