import React, { useEffect, useState } from 'react';
import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import {
  generateDummyOfferTemplateInput,
  applyFormattingToInput,
  generateHtmlOffer,
  OfferTemplatePageOptions,
  OfferTemplateContentBlock,
  OfferTemplateInput,
} from 'semshared/offer/offer';
import { MediaSelector, MediaSelectorType } from 'semshared/offer/mediaselector';
import { ErrorAlert } from 'pages/error';

const MEDIA_SELECTOR_QUERY = gql(`
  query MediaSelectorDehydrate($spaceId: Int!) {
    mediaSelectorDehydrate(spaceId: $spaceId)
  }
`);

interface HTMLRendererProps {
  previewHtml: string | null;
  previewLoading: boolean;
  previewError: string | null;
}

export function PreviewHTMLRenderer({ previewHtml, previewLoading, previewError }: HTMLRendererProps) {
  if (previewError) {
    return <ErrorAlert err={previewError} />;
  }

  if (previewLoading || !previewHtml) {
    return (
      <center>
        <CircularProgress />
      </center>
    );
  }

  return <iframe srcDoc={previewHtml} width="100%" height="100%"></iframe>;
}

interface HTMLOfferTemplateRendererProps {
  spaceId: number;
  contentBlocks: OfferTemplateContentBlock[];
  pageOptions: OfferTemplatePageOptions;
}

export function HTMLOfferTemplateRenderer(props: HTMLOfferTemplateRendererProps) {
  const { data, loading, error } = useQuery(MEDIA_SELECTOR_QUERY, {
    variables: { spaceId: props.spaceId },
  });
  if (loading) {
    return <PreviewHTMLRenderer previewError={null} previewLoading={true} previewHtml={null} />;
  } else if (error) {
    return <PreviewHTMLRenderer previewError={error.message} previewLoading={false} previewHtml={null} />;
  } else if (data) {
    const ms = new MediaSelector();
    ms.hydrate(data.mediaSelectorDehydrate);
    return <HTMLOfferTemplateRendererFull {...props} mediaSelector={ms} />;
  } else {
    return null;
  }
}

interface HTMLOfferTemplateRendererPropsFull extends HTMLOfferTemplateRendererProps {
  mediaSelector: MediaSelectorType;
}

function HTMLOfferTemplateRendererFull({ spaceId, mediaSelector, contentBlocks, pageOptions }: HTMLOfferTemplateRendererPropsFull) {
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);
  const [previewError, setPreviewError] = useState<string | null>(null);

  useEffect(() => {
    setPreviewLoading(true);
    const dummyOffer = generateDummyOfferTemplateInput(pageOptions, contentBlocks);
    const dummyOfferFormatted = applyFormattingToInput(spaceId, dummyOffer);

    generateHtmlOffer(dummyOfferFormatted, async mediaName => {
      if (mediaName) {
        try {
          const media = mediaSelector.getMedia({ mediaName });
          return media.url;
        } catch (err) {
          console.log(err);
          return '';
        }
      } else return '';
    })
      .then(html => {
        setPreviewError(null);
        setPreviewLoading(false);
        setPreviewHtml(html);
      })
      .catch(err => {
        setPreviewError(err.message);
        setPreviewLoading(false);
        setPreviewHtml(null);
      });
  }, [mediaSelector, pageOptions, contentBlocks]);

  return <PreviewHTMLRenderer previewError={previewError} previewLoading={previewLoading} previewHtml={previewHtml} />;
}

interface HTMLOfferRendererProps {
  spaceId: number;
  input: OfferTemplateInput;
}

export function HTMLOfferRenderer(props: HTMLOfferRendererProps) {
  const { data, loading, error } = useQuery(MEDIA_SELECTOR_QUERY, {
    variables: { spaceId: props.spaceId },
  });
  if (loading) {
    return <PreviewHTMLRenderer previewError={null} previewLoading={true} previewHtml={null} />;
  } else if (error) {
    return <PreviewHTMLRenderer previewError={error.message} previewLoading={false} previewHtml={null} />;
  } else if (data) {
    const ms = new MediaSelector();
    ms.hydrate(data.mediaSelectorDehydrate);
    return <HTMLOfferRendererFull {...props} mediaSelector={ms} />;
  } else {
    return null;
  }
}

interface HTMLOfferRendererPropsFull extends HTMLOfferRendererProps {
  mediaSelector: MediaSelectorType;
}

function HTMLOfferRendererFull({ spaceId, mediaSelector, input }: HTMLOfferRendererPropsFull) {
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);
  const [previewError, setPreviewError] = useState<string | null>(null);

  useEffect(() => {
    setPreviewLoading(true);
    const offerFormatted = applyFormattingToInput(spaceId, input);
    generateHtmlOffer(offerFormatted, async mediaName => {
      if (mediaName) {
        try {
          const media = mediaSelector.getMedia({ mediaName });
          return media.url;
        } catch (err) {
          console.log(err);
          return '';
        }
      } else return '';
    })
      .then(html => {
        setPreviewError(null);
        setPreviewLoading(false);
        setPreviewHtml(html);
      })
      .catch(err => {
        setPreviewError(err.message);
        setPreviewLoading(false);
        setPreviewHtml(null);
      });
  }, [input]);

  return <PreviewHTMLRenderer previewError={previewError} previewLoading={previewLoading} previewHtml={previewHtml} />;
}
