import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Routes, Route, Outlet, useParams } from 'react-router-dom';

import Invoice from './invoice';

export default function OffersIndex() {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route path=":id" Component={() => <Invoice id={parseInt(useParams().id!)} />} />
              <Route path=":id/:versionId" Component={() => <Invoice id={parseInt(useParams().id!)} />} />
            </Routes>
            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
