import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { Box, Button, Grid, CircularProgress, Divider, TextField, Typography, Switch } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PlusIcon from '@mui/icons-material/Add';

import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { hintValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputDate } from 'components/form/FormInputDate';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';

import SimpleAccordion from 'components/SimpleAccordion';
import { EditorAccordion } from 'components/template_editor/EditorAccordion';
import { QuickPriceCalculatorContext } from 'components/template_editor/context';
import { HTMLOfferRenderer } from 'components/template_editor/HTMLRenderer';

import {
  OFFERVERSION_VIEW_QUERY,
  OFFERVERSION_QUICKPRICE_QUERY,
  OFFERVERSION_PRODUCTTEXT_QUERY,
  OFFERVERSION_UPSERT_MUTATION,
  REFETCH_OFFER_QUERIES,
  EVICT_OFFER_QUERIES,
} from './gql';
import {
  EOfferVersionContentBlockType,
  EContentProductDetailPosition,
  WLOfferVersionListOutput,
  EOfferVersionStatus,
  EOfferRoomOccupancy,
  EOfferRoomSeating,
  EOfferVersionType,
  ViewWlOfferVersionQuery,
  ECountryCode,
  ELanguageCode,
} from '__generated__/graphql';

import { QuickPriceCalculator, QuickPriceCalculatorType } from '../../semshared/pricelist/quickprice';
import { ProductTextSelector, ProductTextSelectorType } from '../../semshared/pricelist/producttextselector';
import { EWL_OfferVersionContentBlockType, mapOfferStatusToDocumentType } from '../../semshared/offer/offer';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { RedirectError } from 'pages/error';
import { FormInputCountryDropdown } from 'components/form/FormInputDropdown';

interface OfferVersionProps {
  versionId: number;
  offerId: number;
  canChange: boolean;
  submitTrigger: number;
  onShowOfferVersion: (id: number) => void;
  onChangeCanSaveState: (canSave: boolean) => void;
}

interface OfferVersionFormProps extends OfferVersionProps {
  data: NonNullable<ViewWlOfferVersionQuery['viewWlOfferVersion']>;
  quickPriceCalculator: QuickPriceCalculatorType;
  productTexts: ProductTextSelectorType;
}

const taxesSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  taxRateId: yup.number().required(),
  name: yup.string().required(),
  rate: yup.number().required(),
  price: yup.number().required(),
});

const componentsSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  name: yup.string().required(),
  price: yup.number().required().label(i18next.t('offerblock-lineitem-priceitem')),
});

const lineItemSchema = yup.object().shape({
  day: yup.number().required(),
  count: yup.number().required().label(i18next.t('offerblock-lineitem-count')),
  sku: yup.string().nullable(),
  spaceId: yup.number().nullable(),
  header: yup.string().required().label(i18next.t('offerblock-lineitem-header')),
  details: yup.string(),
  priceItem: yup.number().required().label(i18next.t('offerblock-lineitem-priceitem')),
  priceGross: yup.number().required(),
  priceNet: yup.number().required(),
  sequence: yup.number().required(),
  components: yup.array().required().of(componentsSchema),
  taxes: yup.array().required().of(taxesSchema),
});

const contentBlockSchema = yup.object().shape({
  sequence: yup.number().required(),
  template: yup
    .string()
    .required()
    .when('type', {
      is: 'PLAIN_HTML',
      then: schema => schema.label(i18next.t('offerblock-plainhtml-html')),
    })
    .when('type', {
      is: 'INPUT_DAYS',
      then: schema => schema.label(i18next.t('offerblock-inputdays-html')),
    })
    .when('type', {
      is: 'LINE_ITEMS',
      then: schema => schema.label(i18next.t('offerblock-lineitems-html')),
    })
    .when('type', {
      is: 'CANCELLATION_ITEMS',
      then: schema => schema.label(i18next.t('offerblock-lineitems-html')),
    })
    .when('type', {
      is: 'CUSTOM_FORM',
      then: schema => schema.label(i18next.t('offerblock-customform-html')),
    }),
  type: yup.mixed<EWL_OfferVersionContentBlockType>().required(),
  day: yup.number().nullable(),
  lineItems: yup.array().of(lineItemSchema),
  formFields: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      type: yup.string().required(),
      name: yup.string().required(),
      label: yup.string().required(),
      sequence: yup.number().required(),
      required: yup.boolean().nullable(),
      value: yup.string().nullable(),
    }),
  ),
});

const validationSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().nullable(),
  filename: yup.string().nullable(),
  version: yup.string().required().label(i18next.t('offertemplate-version')),
  offerDate: yup.date().required().label(i18next.t('offertemplate-offerdate')),
  startDate: yup.date().required().label(i18next.t('offertemplate-startdate')),
  pageOptions: yup.object().shape({
    marginTop: yup.number().required().label(i18next.t('offertemplate-marginTop')),
    marginBottom: yup.number().required().label(i18next.t('offertemplate-marginBottom')),
    marginLeft: yup.number().required().label(i18next.t('offertemplate-marginLeft')),
    marginRight: yup.number().required().label(i18next.t('offertemplate-marginRight')),
    cssStyles: yup.string().nullable(),
    headerTemplate: yup.string().nullable(),
    footerTemplate: yup.string().nullable(),
  }),
  includeEmptyLineItems: yup.boolean().required(),
  contentBlocks: yup.array().required().min(1).of(contentBlockSchema).label(i18next.t('offertemplate-layout')),
  totalPriceNet: yup.number().required(),
  totalPriceGross: yup.number().required(),
  totalTaxes: yup.array().required().of(taxesSchema),
  additionalInfo1: yup.string().nullable(),
  additionalInfo2: yup.string().nullable(),
  additionalInfo3: yup.string().nullable(),
  client: yup.object().shape({
    email: yup.string().required().label(i18next.t('offertemplate-client-email')),
    phone: yup.string().nullable().label(i18next.t('offertemplate-client-phone')),
    firstname: yup.string().required().label(i18next.t('offertemplate-client-company-firstname')),
    lastname: yup.string().required().label(i18next.t('offertemplate-client-company-lastname')),
    company: yup.string().required().label(i18next.t('offertemplate-client-company-company')),
    country: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('offertemplate-client-company-country')),
    address: yup.string().required().label(i18next.t('offertemplate-client-company-address')),
    city: yup.string().required().label(i18next.t('offertemplate-client-company-city')),
    zip: yup.string().required().label(i18next.t('offertemplate-client-company-zip')),
    billingCompany: yup.string().required().label(i18next.t('offertemplate-client-billing-company')),
    billingFirstname: yup.string().required().label(i18next.t('offertemplate-client-billing-firstname')),
    billingLastname: yup.string().required().label(i18next.t('offertemplate-client-billing-lastname')),
    billingAddress: yup.string().required().label(i18next.t('offertemplate-client-billing-address')),
    billingCity: yup.string().required().label(i18next.t('offertemplate-client-billing-city')),
    billingZip: yup.string().required().label(i18next.t('offertemplate-client-billing-zip')),
    billingCountry: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('offertemplate-client-billing-country'))
  }),
  input: yup.object().nullable().shape({
    language: yup.mixed<ELanguageCode>().required().oneOf(Object.values(ELanguageCode)),
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    prevdayGuests: yup.number().required(),
    comment: yup.string().nullable(),
    serviceType: yup.object().nullable().shape({
      sku: yup.string().required(),
      name: yup.string().required(),
      header: yup.string().required(),
      details: yup.string()
    }),
    days: yup.array().required().of(
      yup.object().shape({
        day: yup.number().required(),
        addons: yup.array().required().of(
          yup.object().shape({
            count: yup.number().nullable(),
            sku: yup.string().required(),
            header: yup.string().required()
          }),
        ),
        occupancy: yup.array().required().of(
          yup.object().shape({
            room: yup.number().required(),
            occupancy: yup.string().required(),
            seating: yup.string().nullable(),
          }),
        ),
        overnightGuests: yup.number().required(),
        totalGuests: yup.number().required(),
      })
    )
  })
});

export type OfferVersionFormType = yup.InferType<typeof validationSchema>;
export type ContentBlockFormType = yup.InferType<typeof contentBlockSchema>;
export type LineItemFormType = yup.InferType<typeof lineItemSchema>;
export type TaxesType = yup.InferType<typeof taxesSchema>;

function OfferVersionForm(props: OfferVersionFormProps) {
  const dispatch = useDispatch();
  const [upsertMutateFunction] = useMutation(OFFERVERSION_UPSERT_MUTATION);

  const [renderCycle, setRenderCycle] = useState(0);
  const [submitTrigger, setSubmitTrigger] = useState(props.submitTrigger)

  const toFormSchema = (obj: NonNullable<ViewWlOfferVersionQuery['viewWlOfferVersion']>): OfferVersionFormType => ({
    id: obj.id,
    title: obj.title,
    filename: obj.filename,
    version: obj.version,
    offerDate: obj.offerDate,
    startDate: obj.startDate,
    additionalInfo1: obj.additionalInfo1,
    additionalInfo2: obj.additionalInfo2,
    additionalInfo3: obj.additionalInfo3,
    contentBlocks: obj.contentBlocks.map(cb => ({
      ...cb,
      formFields: cb.formFields.map(ff => ({
        name: ff.name,
        type: ff.type,
        label: ff.label,
        sequence: ff.sequence,
        required: ff.required,
        value: ff.value,
        id: ff.id,
      })),
      day: cb.lineItems[0] ? cb.lineItems[0].day : 0,
      lineItems: cb.lineItems.map(li => ({
        ...li,
        components: li.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price,
        })),
        taxes: li.taxes.map(t => ({
          taxTypeId: t.type.id,
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price,
        })),
      })),
    })),
    totalPriceNet: obj.totalPriceNet,
    totalPriceGross: obj.totalPriceGross,
    totalTaxes: obj.taxes.map(t => ({
      taxTypeId: t.type.id,
      taxRateId: t.rate.id,
      name: t.type.name,
      rate: t.rate.rate,
      price: t.price,
    })),
    pageOptions: {
      marginTop: obj.marginTop || 0,
      marginBottom: obj.marginBottom || 0,
      marginLeft: obj.marginLeft || 0,
      marginRight: obj.marginRight || 0,
      cssStyles: obj.cssStyles,
      headerTemplate: obj.headerTemplate,
      footerTemplate: obj.footerTemplate,
    },
    includeEmptyLineItems: obj.includeEmptyLineItems,
    client: {
      ...obj.client,
    },
    ...(obj.input ? {
      input: {
        ...obj.input,
        serviceType: obj.input.serviceType || null
      }
    } : { input: null })
  });

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as NonNullable<ViewWlOfferVersionQuery['viewWlOfferVersion']>),
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors: validationErrors, isValid, isDirty, isValidating, isSubmitting },
  } = form;

  const {
    fields: contentBlocksFields,
    remove: contentBlocksRemove,
    replace: contentBlocksReplace,
  } = useFieldArray({
    control,
    name: 'contentBlocks',
  });

  const [advanced, setAdvanced] = useState(false);

  const [switchOfferVersionId, setSwitchOfferVersionId] = useState(0);
  useEffect(() => {
    if (switchOfferVersionId > 0) props.onShowOfferVersion(switchOfferVersionId);
  }, [switchOfferVersionId]);

  useEffect(() => {
    if (props.canChange && submitTrigger !== props.submitTrigger) {
      handleSubmit(onSubmit())()
      setSubmitTrigger(props.submitTrigger)
    }
  }, [props.submitTrigger])

  useEffect(() => {
    props.onChangeCanSaveState(props.canChange && isDirty && isValid && !isSubmitting && !isValidating)
  }, [isDirty, isSubmitting, isValidating, isValid]);

  const onSubmit = () => async (values: OfferVersionFormType) => {
    try {
      const res = await upsertMutateFunction({
        variables: {
          id: props.versionId,
          data: {
            offerId: props.offerId,
            clientId: props.data.client.id,
            additionalInfo1: values.additionalInfo1,
            additionalInfo2: values.additionalInfo2,
            additionalInfo3: values.additionalInfo3,
            client: {
              email: values.client.email,
              phone: values.client.phone,
              firstname: values.client.firstname,
              lastname: values.client.lastname,
              company: values.client.company,
              country: values.client.country,
              address: values.client.address,
              city: values.client.city,
              zip: values.client.zip,
              billingCompany: values.client.billingCompany,
              billingFirstname: values.client.billingFirstname,
              billingLastname: values.client.billingLastname,
              billingAddress: values.client.billingAddress,
              billingCity: values.client.billingCity,
              billingZip: values.client.billingZip,
              billingCountry: values.client.billingCountry,
            },
            input: values.input ? {
              startDate: values.startDate,
              endDate: moment(values.startDate).add(Math.max(...values.input.days.map(day => day.day)), 'days').toDate(),
              language: values.input.language,
              prevdayGuests: values.input.prevdayGuests,
              serviceTypeSku: values.input.serviceType?.sku,
              days: values.input.days.map(day => ({
                day: day.day,
                overnightGuests: day.overnightGuests,
                totalGuests: day.totalGuests,
                occupancy: day.occupancy.map(o => o.occupancy as EOfferRoomOccupancy),
                seating: day.occupancy.map(o => (o.seating || null) as EOfferRoomSeating | null),
                addons: day.addons.map(a => ({
                  sku: a.sku,
                  count: a.count
                }))
              }))
            } : null,
            hotelId: props.data.hotel.id,
            priceListId: props.data.priceList.id,
            version: values.version,
            title: values.title || null,
            filename: values.filename || null,
            offerDate: values.offerDate,
            startDate: values.startDate,
            headerTemplate: values.pageOptions.headerTemplate || null,
            footerTemplate: values.pageOptions.footerTemplate || null,
            marginTop: values.pageOptions.marginTop,
            marginBottom: values.pageOptions.marginBottom,
            marginLeft: values.pageOptions.marginLeft,
            marginRight: values.pageOptions.marginRight,
            includeEmptyLineItems: values.includeEmptyLineItems,
            cssStyles: values.pageOptions.cssStyles,
            contentBlocks: values.contentBlocks.map(cb => ({
              template: cb.template,
              sequence: cb.sequence,
              type: cb.type as EOfferVersionContentBlockType,
              formFields: (cb.formFields || []).map(ff => ({
                sequence: ff.sequence,
                type: ff.type,
                label: ff.label,
                name: ff.name,
                value: ff.value,
                required: ff.required,
              })),
              lineItems: (cb.lineItems || []).map(li => ({
                day: li.day,
                sku: li.sku || null,
                header: li.header,
                details: li.details || '',
                count: li.count,
                sequence: li.sequence,
                priceItem: li.priceItem,
                priceNet: li.priceNet,
                priceGross: li.priceGross,
                components: li.components.map(t => ({
                  price: t.price,
                  taxTypeId: t.taxTypeId,
                })),
                taxes: li.taxes.map(t => ({
                  price: t.price,
                  taxRateId: t.taxRateId
                })),
              })),
            })),
            totalPriceGross: values.totalPriceGross,
            totalPriceNet: values.totalPriceNet,
            taxes: values.totalTaxes.map(t => ({
              price: t.price,
              taxRateId: t.taxRateId
            })),
          },
        },
        update: cache => EVICT_OFFER_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_OFFER_QUERIES(props.offerId, props.data.id),
      });
      reset(toFormSchema(res.data!.upsertOfferVersion as WLOfferVersionListOutput));
      dispatchMessage(dispatch, i18next.t('offerversion-saved'));
      setSwitchOfferVersionId(res.data!.upsertOfferVersion.id);
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  watch(() => {
    setRenderCycle(renderCycle + 1);
  });

  const onLineItemSKUAdded = (cbIndex: number, sku: string) => {
    const contentBlock = getValues(`contentBlocks.${cbIndex}`);
    const lineItemDate = moment(getValues('startDate')).add(contentBlock.day!, 'days').toDate();

    try {
      const header = props.productTexts.getProductText({
        sku: sku,
        hotelId: props.data.hotel.id,
        position: EContentProductDetailPosition.HEADER,
        language: props.data.offer.language
      });
      const details = props.productTexts.getProductText({
        sku: sku,
        hotelId: props.data.hotel.id,
        position: EContentProductDetailPosition.DETAILS,
        language: props.data.offer.language
      });

      const calcLineItem = props.quickPriceCalculator.getSKULineItem(sku, lineItemDate, 1);
      if (calcLineItem) {
        return {
          sku: calcLineItem.sku,
          count: calcLineItem.count,
          priceItem: calcLineItem.priceItem,
          priceGross: calcLineItem.priceGross,
          priceNet: calcLineItem.priceNet,
          day: contentBlock.day!,
          header: header || calcLineItem.name,
          details: details || '',
          sequence: 1000,
          components: calcLineItem.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: calcLineItem.taxes.map(t => ({
            taxTypeId: t.type.id,
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
        };
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const onLineItemFreeAdded = (cbIndex: number, taxTypeId: number) => {
    const contentBlock = getValues(`contentBlocks.${cbIndex}`);
    const lineItemDate = moment(getValues('startDate')).add(contentBlock.day!, 'days').toDate();

    try {
      const priceList = props.quickPriceCalculator.getPriceList({
        date: lineItemDate,
      });
      const calcLineItem = props.quickPriceCalculator.getFreeStyleLineItem(1, 0, priceList.isPricesNet, taxTypeId);
      return {
        count: 1,
        priceItem: 0,
        priceGross: 0,
        priceNet: 0,
        day: contentBlock.day!,
        header: '',
        details: '',
        sequence: 1000,
        components: calcLineItem.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price,
        })),
        taxes: calcLineItem.taxes.map(t => ({
          taxTypeId: t.type.id,
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price,
        })),
      };
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const onTotalsChanged = () => {
    let totalPriceNet = 0;
    let totalPriceGross = 0;
    const totalTaxes = [] as TaxesType[];
    for (const cb of getValues('contentBlocks')) {
      for (const li of cb.lineItems || []) {
        totalPriceNet += li.priceNet;
        totalPriceGross += li.priceGross;
        for (const tax of li.taxes || []) {
          const existingTax = totalTaxes.find(t => t.taxTypeId === tax.taxTypeId);
          if (existingTax) {
            existingTax.price += tax.price;
          } else {
            totalTaxes.push({ ...tax });
          }
        }
      }
    }
    setValue('totalPriceNet', totalPriceNet);
    setValue('totalPriceGross', totalPriceGross);
    setValue('totalTaxes', _.orderBy(totalTaxes, [t => t.name], 'asc'));
  };

  const onLineItemPriceComponentChanged = (cbIndex: number, liIndex: number, componentIndex: number) => {
    const lineItem = getValues(`contentBlocks.${cbIndex}.lineItems.${liIndex}`);
    const priceItem = lineItem.components.reduce((s, c) => s + c.price, 0);
    setValue(`contentBlocks.${cbIndex}.lineItems.${liIndex}.priceItem`, priceItem);
  };
  const onLineItemChanged = (cbIndex: number, liIndex: number) => {
    const contentBlock = getValues(`contentBlocks.${cbIndex}`);
    const lineItem = getValues(`contentBlocks.${cbIndex}.lineItems.${liIndex}`);
    const lineItemDate = moment(getValues('startDate')).add(contentBlock.day!, 'days').toDate();

    try {
      const priceList = props.quickPriceCalculator.getPriceList({
        date: lineItemDate,
      });

      let calcLineItem = null;
      if (lineItem.sku) {
        try {
          calcLineItem = props.quickPriceCalculator.getSKULineItem(lineItem.sku, lineItemDate, lineItem.count, {
            price: lineItem.priceItem,
            bundlePriceFromProduct: false,
            components: lineItem.components.map(c => ({
              taxTypeId: c.taxTypeId,
              price: c.price,
            })),
          });
        } catch (err) {
          calcLineItem = props.quickPriceCalculator.getFreeStyleLineItem(
            lineItem.count,
            lineItem.priceItem,
            priceList.isPricesNet,
            lineItem.taxes[0].taxTypeId,
          );
        }
      } else {
        calcLineItem = props.quickPriceCalculator.getFreeStyleLineItem(
          lineItem.count,
          lineItem.priceItem,
          priceList.isPricesNet,
          lineItem.taxes[0].taxTypeId,
        );
      }

      if (calcLineItem) {
        setValue(`contentBlocks.${cbIndex}.lineItems.${liIndex}.priceGross`, calcLineItem.priceGross);
        setValue(`contentBlocks.${cbIndex}.lineItems.${liIndex}.priceNet`, calcLineItem.priceNet);
        setValue(
          `contentBlocks.${cbIndex}.lineItems.${liIndex}.components`,
          calcLineItem.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
        );
        setValue(
          `contentBlocks.${cbIndex}.lineItems.${liIndex}.taxes`,
          calcLineItem.taxes.map(t => ({
            taxTypeId: t.type.id,
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
        );
      }
      onTotalsChanged();
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const onContentBlockDelete = (index: number) => {
    contentBlocksRemove(index);
    _reassignSequence(getValues('contentBlocks'));
    onTotalsChanged();
  };

  const _reassignSequence = (contentBlocks: ContentBlockFormType[]) => {
    contentBlocks.forEach((cb, index) => {
      cb.sequence = index + 1;
    });
    contentBlocksReplace(contentBlocks);
  };

  return (
    <QuickPriceCalculatorContext.Provider value={props.quickPriceCalculator}>
      <FormProvider {...form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <HTMLOfferRenderer
                  key={`HTMLRenderer_${renderCycle}`}
                  spaceId={props.data.space.id}
                  input={{
                    documentType: mapOfferStatusToDocumentType(props.data.offer.status, props.data.status),
                    date: moment(watch('offerDate')).toDate(),
                    startDate: moment(watch('startDate')).toDate(),
                    expirationDate: moment(watch('offerDate'))
                      .add(props.data.hotel.offerExpirationDays || 30, 'days')
                      .toDate(),
                    hotel: props.data.hotel,
                    serviceType: props.data.input?.serviceType,
                    client: watch('client'),
                    contentBlocks: watch('contentBlocks'),
                    pageOptions: watch('pageOptions'),
                    includeEmptyLineItems: watch('includeEmptyLineItems'),
                    totalPriceNet: watch('totalPriceNet'),
                    totalPriceGross: watch('totalPriceGross'),
                    totalTax: watch('totalPriceGross') - watch('totalPriceNet'),
                    totalTaxes: watch('totalTaxes'),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <UnsavedChangesPrompt isDirty={isDirty} />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Switch checked={advanced} onChange={() => setAdvanced(!advanced)} />
                    {i18next.t('offertemplate-advanced')}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText textFieldProps={{
                      multiline: true,
                      rows: 4,
                    }} name="version" control={control} label={i18next.t('offertemplate-version')} required disabled={!props.canChange}/>
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleAccordion header={`${i18next.t('offertemplate-section-offer')}`}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormInputDate name="startDate" control={control} label={i18next.t('offertemplate-startdate')} required disabled={!props.canChange}/>
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputDate name="offerDate" control={control} label={i18next.t('offertemplate-offerdate')} required disabled={!props.canChange}/>
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleAccordion
                      header={`${i18next.t('offertemplate-section-client')}`}
                      icon={<>{hintValidationAlert(validationErrors.client)}</>}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormInputText name="client.email" control={control} label={i18next.t('offertemplate-client-email')} required disabled={!props.canChange}/>
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText name="client.phone" control={control} label={i18next.t('offertemplate-client-phone')} required={false} disabled={!props.canChange}/>
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleAccordion
                            header={`${i18next.t('offertemplate-section-client-company')}`}
                            icon={
                              <>
                                {validationErrors.client &&
                                  hintValidationAlert(
                                    _.omit(validationErrors.client, [
                                      'email',
                                      'phone',
                                      ...Object.keys(validationErrors.client).filter(k => k.startsWith('billing')),
                                    ]),
                                  )}
                              </>
                            }
                          >
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <FormInputText
                                  name="client.company"
                                  control={control}
                                  label={i18next.t('offertemplate-client-company-company')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText
                                  name="client.firstname"
                                  control={control}
                                  label={i18next.t('offertemplate-client-company-firstname')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText
                                  name="client.lastname"
                                  control={control}
                                  label={i18next.t('offertemplate-client-company-lastname')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormInputText
                                  name="client.address"
                                  control={control}
                                  label={i18next.t('offertemplate-client-company-address')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText name="client.zip" control={control} label={i18next.t('offertemplate-client-company-zip')} required disabled={!props.canChange}/>
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText name="client.city" control={control} label={i18next.t('offertemplate-client-company-city')} required disabled={!props.canChange}/>
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputCountryDropdown
                                  name="client.country"
                                  control={control}
                                  label={i18next.t('offertemplate-client-company-country')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleAccordion
                            header={`${i18next.t('offertemplate-section-client-billing')}`}
                            icon={
                              <>
                                {validationErrors.client &&
                                  hintValidationAlert(
                                    _.omit(
                                      validationErrors.client,
                                      Object.keys(validationErrors.client).filter(k => !k.startsWith('billing')),
                                    ),
                                  )}
                              </>
                            }
                          >
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <FormInputText
                                  name="client.billingCompany"
                                  control={control}
                                  label={i18next.t('offertemplate-client-billing-company')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText
                                  name="client.billingFirstname"
                                  control={control}
                                  label={i18next.t('offertemplate-client-billing-firstname')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText
                                  name="client.billingLastname"
                                  control={control}
                                  label={i18next.t('offertemplate-client-billing-lastname')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormInputText
                                  name="client.billingAddress"
                                  control={control}
                                  label={i18next.t('offertemplate-client-billing-address')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText
                                  name="client.billingZip"
                                  control={control}
                                  label={i18next.t('offertemplate-client-billing-zip')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputText
                                  name="client.billingCity"
                                  control={control}
                                  label={i18next.t('offertemplate-client-billing-city')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormInputCountryDropdown
                                  name="client.billingCountry"
                                  control={control}
                                  label={i18next.t('offertemplate-client-billing-country')}
                                  required
                                  disabled={!props.canChange}
                                />
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleAccordion header={`${i18next.t('offertemplate-section-additional-info')}`}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormInputText textFieldProps={{
                            multiline: true,
                            rows: 4,
                          }} name="additionalInfo1" control={control} label={i18next.t('offertemplate-additionalinfo1')} disabled={!props.canChange}/>
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText textFieldProps={{
                            multiline: true,
                            rows: 4,
                          }} name="additionalInfo2" control={control} label={i18next.t('offertemplate-additionalinfo2')} disabled={!props.canChange}/>
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText textFieldProps={{
                            multiline: true,
                            rows: 4,
                          }} name="additionalInfo3" control={control} label={i18next.t('offertemplate-additionalinfo3')} disabled={!props.canChange}/>
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                  {advanced && (
                    <>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-document')}`}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormInputText name="title" control={control} label={i18next.t('offertemplate-title')} disabled={!props.canChange}/>
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText name="filename" control={control} label={i18next.t('offertemplate-filename')} disabled={!props.canChange}/>
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputCheckbox
                                name="includeEmptyLineItems"
                                control={control}
                                label={i18next.t('offertemplate-includeemptylineitems')}
                                disabled={!props.canChange}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-header')}`}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormInputText
                                name="pageOptions.headerTemplate"
                                label={i18next.t('offertemplate-header')}
                                textFieldProps={{
                                  multiline: true,
                                  rows: 10,
                                }}
                                control={control}
                                disabled={!props.canChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText
                                name="pageOptions.footerTemplate"
                                label={i18next.t('offertemplate-footer')}
                                textFieldProps={{
                                  multiline: true,
                                  rows: 10,
                                }}
                                control={control}
                                disabled={!props.canChange}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion
                          header={`${i18next.t('offertemplate-section-page')}`}
                          icon={<>{hintValidationAlert(validationErrors.pageOptions)}</>}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <FormInputNumber name="pageOptions.marginTop" label={i18next.t('offertemplate-marginTop')} control={control} required disabled={!props.canChange}/>
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginBottom"
                                label={i18next.t('offertemplate-marginBottom')}
                                control={control}
                                required
                                disabled={!props.canChange}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginLeft"
                                label={i18next.t('offertemplate-marginLeft')}
                                control={control}
                                required
                                disabled={!props.canChange}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginRight"
                                label={i18next.t('offertemplate-marginRight')}
                                control={control}
                                required
                                disabled={!props.canChange}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-css')}`}>
                          <FormInputText
                            name="pageOptions.cssStyles"
                            label={i18next.t('offertemplate-css')}
                            textFieldProps={{
                              multiline: true,
                              rows: 10,
                            }}
                            control={control}
                            disabled={!props.canChange}
                          />
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <DragDropContext
                          onDragEnd={result => {
                            if (result.destination) {
                              const contentBlocks = getValues('contentBlocks');
                              const [reorderedItem] = contentBlocks.splice(result.source.index, 1);
                              contentBlocks.splice(result.destination.index, 0, reorderedItem);
                              _reassignSequence(contentBlocks);
                            }
                          }}
                        >
                          <Droppable droppableId="characters">
                            {(provided: any) => (
                              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className="characters">
                                {contentBlocksFields.map((cb, index) => (
                                  <Draggable key={`${cb.id}_${cb.sequence}_s`} draggableId={`${index}_s`} index={index} isDragDisabled={!props.canChange || cb.type === 'LINE_ITEMS' || cb.type === 'CANCELLATION_ITEMS'}>
                                    {(provided: any) => (
                                      <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <EditorAccordion<LineItemFormType>
                                          cssStyles={watch('pageOptions.cssStyles') || ''}
                                          contentBlock={cb}
                                          showHtmlTab={true}
                                          index={index}
                                          control={control}
                                          onDelete={onContentBlockDelete}
                                          onTotalsChanged={onTotalsChanged}
                                          onLineItemChanged={onLineItemChanged}
                                          onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                                          onLineItemSKUAdded={onLineItemSKUAdded}
                                          onLineItemFreeAdded={onLineItemFreeAdded}
                                          disabled={!props.canChange}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                              </Grid>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    </>
                  )}
                  {!advanced &&
                    contentBlocksFields.map((cb, index) => {
                      if (cb.type === 'LINE_ITEMS' || cb.type === 'CANCELLATION_ITEMS') {
                        return (
                          <Grid item xs={12} key={`${cb.id}_${cb.sequence}_s`}>
                            <EditorAccordion
                              cssStyles={watch('pageOptions.cssStyles') || ''}
                              showHtmlTab={false}
                              contentBlock={cb}
                              index={index}
                              control={control}
                              onDelete={onContentBlockDelete}
                              onTotalsChanged={onTotalsChanged}
                              onLineItemChanged={onLineItemChanged}
                              onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                              onLineItemSKUAdded={onLineItemSKUAdded}
                              onLineItemFreeAdded={onLineItemFreeAdded}
                              disabled={!props.canChange}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  {props.canChange && <Grid item xs={12}>
                    <Button
                      sx={{ marginRight: 2 }}
                      variant="contained"
                      color="secondary"
                      startIcon={<PlusIcon />}
                      disabled={isSubmitting || isValidating || !contentBlocksFields.find(c => c.type === 'LINE_ITEMS') || !!contentBlocksFields.find(c => c.type === 'LINE_ITEMS' && c.day === -1)}
                      onClick={async () => {
                        const firstLineItemBlockIndex = contentBlocksFields.findIndex(c => c.type === 'LINE_ITEMS')
                        if (firstLineItemBlockIndex >= 0) {
                          const contentBlocks = [...getValues('contentBlocks')];
                          contentBlocks.splice(firstLineItemBlockIndex, 0, {
                            day: -1,
                            type: 'LINE_ITEMS',
                            sequence: -1,
                            template: contentBlocksFields[firstLineItemBlockIndex].template,
                            lineItems: [],
                            formFields: [],
                          });
                          _reassignSequence(contentBlocks);
                        }
                      }}
                    >
                      {i18next.t('offertemplate-add-previousday')}
                    </Button>
                    <Button
                      sx={{ marginRight: 2 }}
                      variant="contained"
                      color="secondary"
                      startIcon={<PlusIcon />}
                      disabled={isSubmitting || isValidating || !contentBlocksFields.find(c => c.type === 'LINE_ITEMS')}
                      onClick={async () => {
                        const lastLineItemBlockIndex = contentBlocksFields.map(c => c.type === 'LINE_ITEMS').lastIndexOf(true)
                        if (lastLineItemBlockIndex >= 0) {
                          const contentBlocks = [...getValues('contentBlocks')];
                          contentBlocks.splice(lastLineItemBlockIndex + 1, 0, {
                            day: contentBlocksFields[lastLineItemBlockIndex].day! + 1,
                            type: 'LINE_ITEMS',
                            sequence: -1,
                            template: contentBlocksFields[lastLineItemBlockIndex].template,
                            lineItems: [],
                            formFields: [],
                          });
                          _reassignSequence(contentBlocks);
                        }
                      }}
                    >
                      {i18next.t('offertemplate-add-day')}
                    </Button>
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </QuickPriceCalculatorContext.Provider>
  );
}

export default function OfferVersion(props: OfferVersionProps) {
  const offerVersionQuery = useQuery(OFFERVERSION_VIEW_QUERY, {
    variables: { id: props.versionId },
  });
  const offerVersionQuickPriceQuery = useQuery(OFFERVERSION_QUICKPRICE_QUERY, {
    variables: { id: props.versionId },
  });
  const offerVersionProductTextQuery = useQuery(OFFERVERSION_PRODUCTTEXT_QUERY, {
    variables: { id: props.versionId },
  });

  const loading = offerVersionQuickPriceQuery.loading || offerVersionProductTextQuery.loading || offerVersionQuery.loading;
  const error = offerVersionQuickPriceQuery.error || offerVersionProductTextQuery.error || offerVersionQuery.error;

  if (loading) return <CircularProgress />;
  if (error) return <RedirectError err={error} />;

  const calc = new QuickPriceCalculator();
  calc.hydrate(offerVersionQuickPriceQuery.data!.offerVersionQuickPriceDehydrate!);

  const pt = new ProductTextSelector();
  pt.hydrate(offerVersionProductTextQuery.data!.offerVersionProductTextDehydrate!);

  return (
    <OfferVersionForm
      quickPriceCalculator={calc}
      productTexts={pt}
      data={offerVersionQuery.data?.viewWlOfferVersion!}
      {...props}
    />
  );
}
