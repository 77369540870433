import _ from 'lodash';
import moment from 'moment';
import { QuickPriceCalculatorType, CalcLineItemType } from '../pricelist/quickprice';

export type EAM_EventProductLookupCodes = "TAGESPAUSCHALE" | "HALBTAGESPAUSCHALE" | "ADDON_COFFEE" | "ADDON_LUNCH" | "ADDON_DINNER" | "ROOM_EZ" | "ROOM_DZ";
export type EAM_EventRoomType = "ROOM_DOUBLE" | "ROOM_SINGLE" | "ROOM_NONE";

const _allLookupCodes: EAM_EventProductLookupCodes[] = ["TAGESPAUSCHALE", "HALBTAGESPAUSCHALE", "ADDON_COFFEE", "ADDON_LUNCH", "ADDON_DINNER", "ROOM_EZ", "ROOM_DZ"]

interface IProfileAttendance extends IDirectPay {
  days: IProfileAttendanceDay[]
}

export interface IProfileAttendanceDay extends IAttendance, IShow, IDirectPay {
  day: number,
}

export interface IAttendance {
  attending?: boolean | null;
  morning?: boolean | null;
  forenoon?: boolean | null;
  lunch?: boolean | null;
  afternoon?: boolean | null;
  dinner?: boolean | null;
  room?: EAM_EventRoomType | null;
}
interface IShow {
  morningShow?: boolean | null;
  forenoonShow?: boolean | null;
  lunchShow?: boolean | null;
  afternoonShow?: boolean | null;
  dinnerShow?: boolean | null;
  roomShow?: boolean | null;
}

interface IDirectPay {
  serviceDirectPay?: boolean | null;
  roomDirectPay?: boolean | null;
  extraDirectPay?: boolean | null;
  parkingDirectPay?: boolean | null;
}

export const isValidAttendanceLookupCode = (code: string): code is EAM_EventProductLookupCodes => {
  return _allLookupCodes.findIndex(a => a === code) >= 0
}

export const mapAttendanceRegistered = (lookupCode: EAM_EventProductLookupCodes, aday: IAttendance) => {
  if (!!aday.attending) {
    if (lookupCode === 'TAGESPAUSCHALE') {
      return aday.forenoon && aday.afternoon
    }
    if (lookupCode === 'HALBTAGESPAUSCHALE') {
      return (aday.forenoon || aday.afternoon) && !(aday.forenoon && aday.afternoon)
    }
    if (lookupCode === 'ADDON_COFFEE') {
      return !!aday.morning
    }
    if (lookupCode === 'ADDON_LUNCH') {
      return !!aday.lunch
    }
    if (lookupCode === 'ADDON_DINNER') {
      return !!aday.dinner
    }
  }
  if (lookupCode === 'ROOM_DZ') {
    return aday.room === 'ROOM_DOUBLE'
  }
  if (lookupCode === 'ROOM_EZ') {
    return aday.room === 'ROOM_SINGLE'
  }
  return false
}

export const mapAttendance2Codes = (aday: IAttendance) => {
  const result: EAM_EventProductLookupCodes[] = []

  if (aday.attending) {
    if (aday.forenoon && aday.afternoon) result.push('TAGESPAUSCHALE')
    if ((aday.forenoon || aday.afternoon) && !(aday.forenoon && aday.afternoon)) result.push('HALBTAGESPAUSCHALE')
    if (aday.morning) result.push('ADDON_COFFEE')
    if (aday.lunch) result.push('ADDON_LUNCH')
    if (aday.dinner) result.push('ADDON_DINNER')
  }
  if (aday.room === 'ROOM_SINGLE') result.push('ROOM_EZ')
  if (aday.room === 'ROOM_DOUBLE') result.push('ROOM_DZ')

  return result
}

export const mapAttendanceShow = (lookupCode: EAM_EventProductLookupCodes, aday: IShow) => {
  const _cf = (flag: string) => _.isBoolean((aday as any)[flag]) ? !!(aday as any)[flag] : true

  if (lookupCode === 'TAGESPAUSCHALE') {
    return _cf('forenoonShow') && _cf('afternoonShow')
  }
  if (lookupCode === 'HALBTAGESPAUSCHALE') {
    return (_cf('forenoonShow') || _cf('afternoonShow')) && !(_cf('forenoonShow') && _cf('afternoonShow'))
  }
  if (lookupCode === 'ADDON_COFFEE') {
    return _cf('morningShow')
  }
  if (lookupCode === 'ADDON_LUNCH') {
    return _cf('lunchShow')
  }
  if (lookupCode === 'ADDON_DINNER') {
    return _cf('dinnerShow')
  }
  if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
    return _cf('roomShow')
  }
  return true
}

export const mapAttendanceDirectPay = (lookupCode: EAM_EventProductLookupCodes, g: IDirectPay, a: IDirectPay, aday: IDirectPay) => {
  const _cf = (flag: string) => _.isBoolean((aday as any)[flag]) ? !!(aday as any)[flag] : _.isBoolean((a as any)[flag]) ? !!(a as any)[flag] : _.isBoolean((g as any)[flag]) ? !!(g as any)[flag] : false

  if (lookupCode === 'TAGESPAUSCHALE' || lookupCode === 'HALBTAGESPAUSCHALE') {
    return _cf('serviceDirectPay')
  }
  if (lookupCode === 'ADDON_COFFEE' || lookupCode === 'ADDON_LUNCH' || lookupCode === 'ADDON_DINNER') {
    return _cf('serviceDirectPay')
  }
  if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
    return _cf('roomDirectPay')
  }
  return false
}

export const mapShowAttendance = (lookupCode: EAM_EventProductLookupCodes, shows: boolean[], attendances: IShow[]) => {
  return shows.map((show, index) => {
    const attendance = attendances[index]
    if (lookupCode === 'TAGESPAUSCHALE') {
      attendance.forenoonShow = show
      attendance.afternoonShow = show
    } else if (lookupCode === 'HALBTAGESPAUSCHALE') {
      attendance.forenoonShow = show
    } else if (lookupCode === 'ADDON_COFFEE') {
      attendance.morningShow = show
    } else if (lookupCode === 'ADDON_LUNCH') {
      attendance.lunchShow = show
    } else if (lookupCode === 'ADDON_DINNER') {
      attendance.dinnerShow = show
    } else if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
      attendance.roomShow = show
    }
    return attendance
  })
}

export const mapProfileAttendanceDay2LineItems = (g: IDirectPay, a: IProfileAttendance, day: IProfileAttendanceDay, qp: QuickPriceCalculatorType) =>  {
  const lineItems: { sku: string, code: EAM_EventProductLookupCodes, count: number }[] = []

  const codes = mapAttendance2Codes(day)
  for (const code of codes) {
    const sku = qp.getSKUForLookupCode(code)
    if (!sku) continue

    const show = mapAttendanceShow(code, day)
    const directPay = mapAttendanceDirectPay(code, g, a, day)

    if (directPay && show) continue

    const li = lineItems.find(l => l.sku === sku)
    if (li) {
      li.count++
    } else {
      lineItems.push({
        sku,
        code,
        count: 1
      })
    }
  }
  return lineItems
}

export const mapLineItems2GuestCount = (lineItems: { sku: string, count: number }[], qp: QuickPriceCalculatorType) =>  {
  const skusOvernight = _.uniq([
    qp.allBundlesDb?.find(b => b.lookupCode === 'ROOM_EZ')?.sku,
    qp.allBundlesDb?.find(b => b.lookupCode === 'ROOM_DZ')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'ROOM_EZ')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'ROOM_DZ')?.sku
  ].filter(s => s).map(s => s!))

  const skusGuests = _.uniq([
    qp.allBundlesDb?.find(b => b.lookupCode === 'TAGESPAUSCHALE')?.sku,
    qp.allBundlesDb?.find(b => b.lookupCode === 'HALBTAGESPAUSCHALE')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'TAGESPAUSCHALE')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'HALBTAGESPAUSCHALE')?.sku
  ].filter(s => s).map(s => s!))

  return {
    overnightGuests: lineItems.filter(li => skusOvernight.includes(li.sku)).reduce((agg, c) => agg + c.count, 0),
    totalGuests: lineItems.filter(li => skusGuests.includes(li.sku)).reduce((agg, c) => agg + c.count, 0),
  }
}

export const mapProfileAttendances2LineItems = (gevent: IDirectPay, attendances: IProfileAttendance[], startDate: Date, qp: QuickPriceCalculatorType) =>  {
  let lineItems: CalcLineItemType[] = []

  const days = _.uniq(attendances.reduce((agg, a) => [...agg, ...a.days.map(d => d.day)], [] as number[])).sort()
  for (const day of days) {
    const date = moment(startDate).add(day, 'days').toDate();

    for (const attendance of attendances) {
      const attendanceDay = attendance.days.find(d => d.day == day)
      if (!attendanceDay) continue;

      const daySkuCounts = mapProfileAttendanceDay2LineItems(gevent, attendance, attendanceDay, qp)
      for (const daySkuCount of daySkuCounts) {
        if (day < 0 && daySkuCount.code !== 'ROOM_EZ' && daySkuCount.code !== 'ROOM_DZ') continue

        const li = lineItems.find(l => l.sku === daySkuCount.sku && l.day === day)
        const itemCount = li ? li.count + 1 : 1
        const newLineItem = qp.getSKULineItem(daySkuCount.sku, date, itemCount, null, attendances.length) //tageweise guest count ?
        if (!newLineItem) continue

        if (li) {
          lineItems = lineItems.filter(r => r.day === li.day ? r.sku !== daySkuCount.sku : true)
        }
        newLineItem.day = day
        lineItems.push(newLineItem)
      }
    }
    for (const code of _allLookupCodes) {
      if (day < 0 && code !== 'ROOM_EZ' && code !== 'ROOM_DZ') continue

      const sku = qp.getSKUForLookupCode(code)
      if (!sku) continue

      const li = lineItems.find(l => l.sku === sku && l.day === day)
      if (!li) {
        const newLineItem = qp.getSKULineItem(sku, date, 0, null, attendances.length)
        if (!newLineItem) continue
        newLineItem.day = day
        lineItems.push(newLineItem)
      }
    }
  }

  return {
    lineItems,
    input: {
      prevdayGuests: mapLineItems2GuestCount(lineItems.filter(li => li.day === -1), qp).overnightGuests,
      days: days.map(day => ({
        day: day,
        overnightGuests: mapLineItems2GuestCount(lineItems.filter(li => li.day === day), qp).overnightGuests,
        totalGuests: mapLineItems2GuestCount(lineItems.filter(li => li.day === day), qp).totalGuests,
      }))
    }
  }
}


