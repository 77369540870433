import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import Notifier from './components/Notifier';
import store from './store';
import router from './layout/router';

const persistor = persistStore(store);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${JSON.stringify(extensions, null, 2)}`),
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);

  if (networkError && (networkError as any).statusCode === 401) {
    client.clearStore().then(() => {
      window.location.pathname = '/login';
    });
  }
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || '/backend/graphql', credentials: 'include' });

const client = new ApolloClient({
  //uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || '/backend/graphql',
  //credentials: 'include',
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
});

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Notifier />
            <RouterProvider router={router} />
          </ThemeProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </ApolloProvider>,
);
