import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, IconButton, Tooltip, Grid, CircularProgress, Toolbar, Container, Paper, Box } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { showValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputCountryDropdown, FormInputDropdown, SimpleDropdown } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpace } from 'helper/security';
import { filterSelector } from 'helper/filter';

import ProductDetailsFlat, {
  ProductDetailsFlatContentSchema,
  ProductDetailsFlatFromTypeToSchema,
  ProductDetailsFlatFromSchemaToType,
} from '../content/products/productdetailsflat_embedded';
import { AvailabilityEmbeddedTable } from '../availability/availability';

import {
  VIEW_BOOKING_QUERY
} from './gql';
import { TAXTYPES_LIST_FULL_QUERY } from '../prices/gql';
import { TaxTypeListOutput, ProductListOutput, EProductPayment, EProductLookupCode, ProductComponentListOutput, HL_PublicClientOutput, HL_PublicCompanyOutput, ECountryCode, HLBookingListOutput } from '__generated__/graphql';
import yup from 'validation';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { ISpace } from 'types';
import { TaxComponentSelectionInput } from 'components/tax/TaxComponentSelectionInput';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import { isProductionRelease } from 'helper/deployment';
import { add } from 'lodash';
import { stat } from 'fs';
import { OpenInNew } from '@mui/icons-material';

interface BookingProps {
  id: number;
}
interface BookingFormProps {
  data: HLBookingListOutput
}

const validationSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
  status: yup.string().required(),
});

function BookingForm(props: BookingFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  //const [updateMutateFunction] = useMutation(UPDATE_PUBLIC_COMPANY_MUTATION);

  const user = userSelector()!;
  const canEditText = user.isAdmin;

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/products/products/${createdId}`);
  }, [createdId]);

  type BookingFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: HLBookingListOutput): BookingFormType => ({
    id: obj.id,
    title: obj.title,
    status: obj.status,
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as HLBookingListOutput),
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: BookingFormType) => {
    /*try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              address: values.address,
              zip: values.zip,
              city: values.city,
              country: values.country,
              isPremium: values.isPremium,
            },
          },
          awaitRefetchQueries: true,
        });
      }
      dispatchMessage(dispatch, i18next.t('publiccompany-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }*/
  };

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('booking-page-header')} / {props.data.title}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
          Status: {props.data.status}
          </Grid>
          <Grid item xs={12}>
          <FormInputText name="title" control={control} label={i18next.t('booking-title')} />
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SimpleTable rows={props.data.offers.map((offer) => [
            offer.hotel.name,
            offer.status,
            <IconButton onClick={() => navigate(`/offers/${offer.id}`)}><OpenInNew /></IconButton>,
          ])} headers={[
            i18next.t('booking-offers-header-hotel'),
            i18next.t('booking-offers-header-status'),
            '',
          ]} />
        </Grid>
      </Grid>
    </>
  );
}

export default function Booking(props: BookingProps) {
  const bookingQuery = useQuery(VIEW_BOOKING_QUERY, {
    variables: { id: props.id },
  });

  if (bookingQuery.loading) return <CircularProgress />;
  else if (bookingQuery.error) return <Alert severity="error">{bookingQuery.error.message}</Alert>
  else
    return (<>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
        <Box p={2}>
      <BookingForm
        data={bookingQuery.data!.viewBooking as HLBookingListOutput}
      />
      </Box>
      </Paper>
      </Container>
      </>
    );
}