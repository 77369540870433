import { TResolveMediaSrc, handlebars } from "./handlebars";

export interface IHtmlTemplateMinimum {
  cssStyles?: string | null;
  bodyParts: string[];
}

export interface IHtmlTemplate extends IHtmlTemplateMinimum {
  marginTop?: number | null;
  marginBottom?: number | null;
  marginLeft?: number | null;
  marginRight?: number | null;
  headerTemplate?: string | null;
  footerTemplate?: string | null;
}

export const pdfTemplate2Html = ({ cssStyles, bodyParts }: IHtmlTemplateMinimum) => {
  return `
  <html>
    <head>
      <style>
        .pagebreak {
          break-inside: avoid;
        }

        tbody {page-break-inside: avoid; width: 100%;}

        ${cssStyles}
      </style>
    </head>
  <body>
    ${bodyParts.join('')}
  </body>
</html>`;
};

export const htmlTemplate2Html = ({ cssStyles, marginTop, marginBottom, marginLeft, marginRight, headerTemplate, footerTemplate, bodyParts }: IHtmlTemplate) => {
  return `
  <html>
  <head>
    <script>
      window.onload = function() {
        var wrapOuter = document.getElementById('wrap-outer')

        window.addEventListener("resize", resize);
        resize();

        function resize(){
          var width = document.documentElement.clientWidth || document.body.clientWidth;
          var scale = width/wrapOuter.getBoundingClientRect().width;
          wrapOuter.style.zoom = scale;
        }
      
      }
    </script>
    <style>
      del {
        background: #ffc6c4;
        margin-right: 5px;
      }

      ins {
        background: #D1FFBD;
        text-decoration: none;
      }

      body, html {
        padding: 0px;
        margin: 0px;
      }
        
      #wrap-outer {
        width: 210mm;
        position: absolute;
      }

      #wrap-inner {
        box-sizing: border-box;
        margin-top: ${marginTop || 0}px;
        margin-bottom: ${marginBottom || 0}px;
        margin-left: ${marginLeft || 0}px;
        margin-right: ${marginRight || 0}px;
      }

      #header {
        position: absolute;
        top: 0px;
      }

      #footer {
        position: absolute;
        bottom: 0px;
      }

      ${cssStyles || ''}
    </style>
  </head>
  <body>
    <div id="wrap-outer">
      <div id="header">
        ${headerTemplate || ''}
      </div>
    <div id="wrap-inner">
      ${bodyParts.join('')}
    </div>
    <div id="footer">
      ${footerTemplate || ''}
    </div>
    </div>
  </body>
</html>
  `;
};

export async function generateHtmlForHtmlTemplate(template: IHtmlTemplate, input: any, resolveMediaSrc?: TResolveMediaSrc | null): Promise<string> {
  const renderedBodyParts = await Promise.all(
    template.bodyParts.map(async (bp) => handlebars(bp, input, resolveMediaSrc))
  );
  return htmlTemplate2Html({
    ...template,
    headerTemplate: template.headerTemplate ? await handlebars(template.headerTemplate, input, resolveMediaSrc) : null,
    footerTemplate: template.footerTemplate ? await handlebars(template.footerTemplate, resolveMediaSrc) : null,
    bodyParts: renderedBodyParts
  });
}

export async function generateHtmlForPdfTemplate(template: IHtmlTemplateMinimum, input: any, resolveMediaSrc?: TResolveMediaSrc | null): Promise<string> {
  const renderedBodyParts = await Promise.all(
    template.bodyParts.map(async (bp) => handlebars(bp, input, resolveMediaSrc))
  );

  return pdfTemplate2Html({
    ...template,
    bodyParts: renderedBodyParts
  });
}
