import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, IconButton, Tooltip, Grid, CircularProgress } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { showValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputDropdown, SimpleDropdown } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpace } from 'helper/security';
import { filterSelector } from 'helper/filter';

import ProductDetailsFlat, {
  ProductDetailsFlatContentSchema,
  ProductDetailsFlatFromTypeToSchema,
  ProductDetailsFlatFromSchemaToType,
} from '../content/products/productdetailsflat_embedded';
import { AvailabilityEmbeddedTable } from '../availability/availability';

import {
  PUBLIC_COMPANY_LIST_QUERY,
  UPDATE_PUBLIC_CLIENT_MUTATION,
  VIEW_PUBLIC_CLIENT_QUERY,
} from './gql';
import { TAXTYPES_LIST_FULL_QUERY } from '../prices/gql';
import { TaxTypeListOutput, ProductListOutput, EProductPayment, EProductLookupCode, ProductComponentListOutput, HL_PublicClientOutput } from '__generated__/graphql';
import yup from 'validation';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { ISpace } from 'types';
import { TaxComponentSelectionInput } from 'components/tax/TaxComponentSelectionInput';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import { isProductionRelease } from 'helper/deployment';

interface PublicClientProps {
  id: number;
}
interface PublicClientFormProps {
  data: HL_PublicClientOutput
}

const validationSchema = yup.object().shape({
  id: yup.number().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  publicCompanyId: yup.number().required(),
});

function PublicClientForm(props: PublicClientFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateMutateFunction] = useMutation(UPDATE_PUBLIC_CLIENT_MUTATION);

  const { data: dataCompanies, loading: loadingCompanies, error: errorCompanies } = useQuery(PUBLIC_COMPANY_LIST_QUERY);

  const user = userSelector()!;
  const canEditText = user.isAdmin;

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/products/products/${createdId}`);
  }, [createdId]);

  type PublicClientFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: HL_PublicClientOutput): PublicClientFormType => ({
    id: obj.id,
    firstname: obj.firstname  || '',
    lastname: obj.lastname || '',
    email: obj.email || '',
    phone: obj.phone || '',
    publicCompanyId: obj.publicCompanyId,
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as PublicClientFormType),
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: PublicClientFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              firstname: values.firstname,
              lastname: values.lastname,
              phone: values.phone,
              publicCompanyId: values.publicCompanyId,
            },
          },
          awaitRefetchQueries: true,
        });
      }
      dispatchMessage(dispatch, i18next.t('publicclient-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('clients-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputText name="firstname" control={control} label={i18next.t('publicclient-firstname')} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText name="lastname" control={control} label={i18next.t('publicclient-lastname')} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText name="phone" control={control} label={i18next.t('publicclient-phone')} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText name="email" control={control} label={i18next.t('publicclient-email')} />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={3}>
         <FormInputDropdown name="publicCompanyId" control={control} label={i18next.t('publicclient-company')} options={(dataCompanies?.listPublicCompanies || []).map(c => ({ value: c.id, label: c.name }))} />
        </Grid>
          <Grid item xs={12}>
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
                onClick={async () => {
                  const valid = await trigger();
                  if (valid) {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                {i18next.t('publicclient-save')}
              </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default function PublicClient(props: PublicClientProps) {
  const clientQuery = useQuery(VIEW_PUBLIC_CLIENT_QUERY, {
    variables: { id: props.id },
  });

  if (clientQuery.loading) return <CircularProgress />;
  else if (clientQuery.error) return <Alert severity="error">{clientQuery.error.message}</Alert>
  else
    return (
      <PublicClientForm
        data={clientQuery.data!.viewPublicClient as HL_PublicClientOutput}
      />
    );
}