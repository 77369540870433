import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, ButtonGroup, IconButton, Tooltip, Grid, CircularProgress, Select } from '@mui/material';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { exportToFile } from 'helper/download';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputDate } from 'components/form/FormInputDate';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputDropdown, buildMenuItemsFromOptions } from 'components/form/FormInputDropdown';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import PagedTable from 'components/table/PagedTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { HotelSelectionInput } from 'components/security/HotelSelectionInput';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpaceId, filterForSelectableRecords } from 'helper/security';
import { filterSelector } from 'helper/filter';

import {
  PRICELIST_VIEW_QUERY,
  CREATE_PRICELIST_MUTATION,
  UPDATE_PRICELIST_MUTATION,
  DELETE_PRICELIST_MUTATION,
  COPY_PRICELIST_MUTATION,
  EXPORT_PRICES_MUTATION,
  REFETCH_PRICELISTS_QUERIES,
  EVICT_PRICELISTS_QUERIES,
  PRICESELECTOR_LIST_QUERY,
  PRICELISTS_LIST_QUERY,
} from './gql';
import { HOTEL_LIST_QUERY } from '../settings/gql';

import { PriceListListOutput, HotelListOutput, ViewPriceListQuery, ListHotelsQuery, ListProductBundlesQuery, ListFacilitiesQuery, ListProductsQuery, ListPriceListsQuery, EPricingRuleType, EPricingRoundingType } from '__generated__/graphql';
import { formatDate } from 'components/DateTime';
import { allCurrencies } from 'languages';

import { PriceSelectorCopyButton, PriceSelectorDeleteButton } from './selector';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { SimpleDropdown } from 'components/form/FormInputDropdown';
import { RedirectError } from 'pages/error';
import { FACILITIES_LIST_QUERY, PRODUCTBUNDLES_LIST_QUERY, PRODUCTS_LIST_QUERY } from 'pages/products/gql';
import { GroupedSpaceSelectionInput, buildGroupedSpaceSelectionOptions } from 'components/security/GroupedSpaceSelectionInput';
import { FormInputPercentage } from 'components/form/FormInputPercentage';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { formatDocumentTitle } from 'helper/usedocumenttitle';

interface PriceListProps {
  id: number;
}
interface PriceListCreateProps {}
interface PriceListFormProps {
  data: NonNullable<ViewPriceListQuery['viewPriceList']>;
  hotels: ListHotelsQuery['listHotels'];
  priceLists: ListPriceListsQuery['listPriceLists'];
  bundles: ListProductBundlesQuery['listProductBundles'];
  facilities: ListFacilitiesQuery['listFacilities'];
  products: ListProductsQuery['listProducts'];
}

const validitySchema = yup.object().shape({
  validFrom: yup.date().nullable(),
  validTo: yup.date().nullable(),
});

const rulesSchema = yup.object().shape({
  rule: yup.mixed<EPricingRuleType>().oneOf(Object.values(EPricingRuleType)).required().label(i18next.t('pricelist-rules-rule')),
  rate: yup.number().required().label(i18next.t('pricelist-rules-rate')),
  rounding: yup.mixed<EPricingRoundingType>().oneOf(Object.values(EPricingRoundingType)).required().label(i18next.t('pricelist-rules-rounding')),
  productId: yup.number().nullable(),
  facilityId: yup.number().nullable(),
  bundleId: yup.number().nullable(),
});

const validationSchema = yup.object().shape({
  spaceId: yup.number().required().label(i18next.t('field-space')),
  name: yup.string().required().label(i18next.t('pricelist-name')),
  version: yup.string().required().label(i18next.t('pricelist-version')),
  refCode: yup.string().nullable(),
  isDefault: yup.boolean().required(),
  isPublished: yup.boolean().required(),
  isPricesBrut: yup.boolean().required(),
  currency: yup.string().required(),
  forLister: yup.boolean().required(),
  forWidget: yup.boolean().required(),
  hotelId: yup.number().nullable(),
  parentPriceListId: yup.number().required(),
  validity: yup.array().of(validitySchema),
  rules: yup.array().of(rulesSchema),
});

function PriceListForm(props: PriceListFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateMutateFunction] = useMutation(UPDATE_PRICELIST_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_PRICELIST_MUTATION);
  const [exportMutateFunction] = useMutation(EXPORT_PRICES_MUTATION);

  const [selectorsQuery, { data: selectorsData, loading: selectorsLoading, error: selectorsError }] = useLazyQuery(PRICESELECTOR_LIST_QUERY);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/pricelists/pricelists/${createdId}`);
  }, [createdId]);

  type PriceListFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: NonNullable<ViewPriceListQuery['viewPriceList']>): PriceListFormType => ({
    ...obj,
    spaceId: obj.space.id,
    isPricesBrut: !obj.isPricesNet,
    hotelId: obj.hotel ? obj.hotel.id : 0,
    parentPriceListId: obj.parent ? obj.parent.id : 0,
    validity: obj.validity.map(v => ({
      validFrom: v.validFrom ? new Date(v.validFrom) : null,
      validTo: v.validTo ? new Date(v.validTo) : null,
    })),
    rules: obj.rules.map(r => ({
      rule: r.rule,
      rate: r.rule === EPricingRuleType.PERCENTAGE ? Math.floor(r.rate * 100) : r.rate,
      rounding: r.rounding,
      productId: r.product ? r.product.id : null,
      facilityId: r.facility ? r.facility.id : null,
      bundleId: r.bundle ? r.bundle.id : null
    }))
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    getValues,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as PriceListListOutput),
  });

  const {
    fields: validityFields,
    append: validityAppend,
    remove: validityRemove,
  } = useFieldArray({
    control,
    name: 'validity',
  });
  const {
    fields: rulesFields,
    append: rulesAppend,
    remove: rulesRemove,
  } = useFieldArray({
    control,
    name: 'rules',
  });
  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: PriceListFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              version: values.version,
              isDefault: values.isDefault,
              isPublished: values.isPublished,
              isPricesNet: !values.isPricesBrut,
              currency: values.currency,
              forLister: values.forLister,
              forWidget: values.forWidget,
              hotelId: values.hotelId && values.hotelId > 0 ? values.hotelId! : null,
              parentPriceListId: values.parentPriceListId && values.parentPriceListId > 0 ? values.parentPriceListId! : null,
              validity: values.validity || [],
              rules: (values.rules || []).map(r => ({
                ...r,
                rate: r.rule === EPricingRuleType.PERCENTAGE ? r.rate / 100 : r.rate,
              }))
            },
          },
          update: cache => EVICT_PRICELISTS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRICELISTS_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updatePriceList || {}) as PriceListListOutput));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              name: values.name,
              version: values.version,
              isDefault: values.isDefault,
              isPricesNet: !values.isPricesBrut,
              currency: values.currency,
              forLister: values.forLister,
              forWidget: values.forWidget,
              hotelId: values.hotelId && values.hotelId > 0 ? values.hotelId! : null,
              parentPriceListId: values.parentPriceListId && values.parentPriceListId > 0 ? values.parentPriceListId! : null,
              validity: values.validity || [],
              rules: (values.rules || []).map(r => ({
                ...r,
                rate: r.rule === EPricingRuleType.PERCENTAGE ? r.rate / 100 : r.rate,
              }))
           },
          },
          update: cache => EVICT_PRICELISTS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRICELISTS_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.createPriceList || {}) as PriceListListOutput));
        setCreatedId(res.data!.createPriceList.id);
      }
      dispatchMessage(dispatch, i18next.t('pricelist-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const _renderRuleScope = (index: number) => {
    const row = getValues(`rules.${index}`)
    if (row && row.productId) {
      const product = props.products.find(p => p.id === row.productId);
      
      return <Link to={`/products/products/${row.productId}`}>
        {product?.name}
        {product?.extRefCode && ` (${product?.extRefCode})`}
      </Link>
    }
    if (row && row.bundleId) {
      const bundle = props.bundles.find(p => p.id === row.bundleId);
      
      return <Link to={`/products/bundles/${row.bundleId}`}>
        {bundle?.name}
        {bundle?.extRefCode && ` (${bundle?.extRefCode})`}
      </Link>
    }
    if (row && row.facilityId) {
      const facility = props.facilities.find(p => p.id === row.facilityId);
      
      return <Link to={`/products/facilities/${row.facilityId}`}>
        {facility?.name}
        {facility?.extRefCode && ` (${facility?.extRefCode})`}
      </Link>
    }
    return <>{i18next.t('pricelist-rules-all')}</>
  }

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('pricelists-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputText name="name" control={control} label={i18next.t('pricelist-name')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="currency"
            control={control}
            label={i18next.t('pricelist-currency')}
            options={[...allCurrencies.map(c => (c ? { value: c.code, label: c.label } : ({ divider: true } as any)))]}
            disabled={!canEdit}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText name="refCode" control={control} label={i18next.t('pricelist-refcode')} disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputCheckbox name="isDefault" control={control} label={i18next.t('pricelist-isdefault')} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText name="version" control={control} label={i18next.t('pricelist-version')} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputCheckbox
            name="isPublished"
            control={control}
            label={i18next.t('pricelist-ispublished')}
            disabled={!canEdit || props.data.id < 0}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HotelSelectionInput
            label={i18next.t('pricelist-hotel')}
            name="hotelId"
            control={control}
            disabled={!canEdit}
            hotels={props.hotels.filter(h => h.space.id === watch('spaceId'))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputCheckbox
            name="isPricesBrut"
            control={control}
            label={i18next.t('pricelist-ispricesbrut')}
            disabled={!canEdit || props.data.id < 0}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputCheckbox
            name="forLister"
            control={control}
            label={i18next.t('pricelist-forlister')}
            disabled={!canEdit || props.data.id < 0}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <FormInputCheckbox
            name="forWidget"
            control={control}
            label={i18next.t('pricelist-forwidget')}
            disabled={!canEdit || props.data.id < 0}
          />
        </Grid>
        {validityFields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Grid item xs={12} sm={4}>
              <FormInputDate name={`validity.${index}.validFrom`} control={control} label={i18next.t('pricelist-validfrom')} disabled={!canEdit} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputDate name={`validity.${index}.validTo`} control={control} label={i18next.t('pricelist-validto')} disabled={!canEdit} />
            </Grid>
            {canEdit && (
              <Grid item xs={12} sm={4}>
                <IconButton onClick={() => validityRemove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </React.Fragment>
        ))}
        {canEdit && (
          <Grid item xs={12}>
            <Button startIcon={<AddIcon />} onClick={() => validityAppend({ validFrom: null, validTo: null })}>
              {i18next.t('pricelist-validity-add')}
            </Button>
          </Grid>
        )}
        {props.data.id > 0 && (
          <Grid item xs={12}>
            <CustomTabs
              headers={[
                ...(props.data.id > 0 && !props.data.validation.isComplete ? [i18next.t('pricelist-validation-tab')] : []),
                i18next.t('pricelist-selectors-tab'),
                i18next.t('pricelist-rules-tab'),
              ]}
              icons={[...(props.data.id > 0 && !props.data.validation.isComplete ? [<ErrorIcon />] : []), undefined, undefined]}
              tabs={[
                ...(props.data.id > 0 && !props.data.validation.isComplete
                  ? [
                      <Grid container spacing={3}>
                        {(props.data.validation.productsWithoutPrice.length > 0 ||
                          props.data.validation.productsWithoutPriceComponents.length > 0) && (
                          <Grid item xs={12} sm={4}>
                            {props.data.validation.productsWithoutPrice.length > 0 && (
                              <SimpleTable
                                headers={[
                                  i18next.t('pricelist-validation-products', {
                                    productCount: `${props.data.validation.productsWithoutPrice.length}`,
                                  }),
                                  ...(user.isSingleSpace ? [] : ['']),
                                  '',
                                ]}
                                rows={props.data.validation.productsWithoutPrice.map(row => [
                                  <Link
                                    to={
                                      props.data.basePriceSelector
                                        ? `/pricelists/pricelists/${props.data.id}/selectors/${props.data.basePriceSelector.id}#${row.sku}`
                                        : `/pricelists/pricelists/${props.data.id}`
                                    }
                                  >
                                    {row.name}
                                  </Link>,
                                  ...(user.isSingleSpace ? [] : [row.space.name]),
                                  row.sku,
                                ])}
                              />
                            )}
                            <br />
                            {props.data.validation.productsWithoutPriceComponents.length > 0 && (
                              <SimpleTable
                                headers={[
                                  i18next.t('pricelist-validation-productcomponents', {
                                    productCount: `${props.data.validation.productsWithoutPriceComponents.length}`,
                                  }),
                                  ...(user.isSingleSpace ? [] : ['']),
                                  '',
                                ]}
                                rows={props.data.validation.productsWithoutPriceComponents.map(row => [
                                  <Link to={`/pricelists/pricelists/${props.data.id}/selectors/${row.selector.id}#${row.product.sku}`}>
                                    {row.product.name}
                                  </Link>,
                                  ...(user.isSingleSpace ? [] : [row.product.space.name]),
                                  row.product.sku,
                                ])}
                              />
                            )}
                          </Grid>
                        )}
                        {(props.data.validation.bundlesWithoutPrice.length > 0 || props.data.validation.bundlesWithoutPriceComponents.length > 0) && (
                          <Grid item xs={12} sm={4}>
                            {props.data.validation.bundlesWithoutPrice.length > 0 && (
                              <SimpleTable
                                headers={[
                                  i18next.t('pricelist-validation-bundles', {
                                    bundleCount: `${props.data.validation.bundlesWithoutPrice.length}`,
                                  }),
                                  ...(user.isSingleSpace ? [] : ['']),
                                  '',
                                ]}
                                rows={props.data.validation.bundlesWithoutPrice.map(row => [
                                  <Link
                                    to={
                                      props.data.basePriceSelector
                                        ? `/pricelists/pricelists/${props.data.id}/selectors/${props.data.basePriceSelector.id}#${row.sku}`
                                        : `/pricelists/pricelists/${props.data.id}`
                                    }
                                  >
                                    {row.name}
                                  </Link>,
                                  ...(user.isSingleSpace ? [] : [row.space.name]),
                                  row.sku,
                                ])}
                              />
                            )}
                            <br />
                            {props.data.validation.bundlesWithoutPriceComponents.length > 0 && (
                              <SimpleTable
                                headers={[
                                  i18next.t('pricelist-validation-bundlecomponents', {
                                    bundleCount: `${props.data.validation.bundlesWithoutPriceComponents.length}`,
                                  }),
                                  ...(user.isSingleSpace ? [] : ['']),
                                  '',
                                ]}
                                rows={props.data.validation.bundlesWithoutPriceComponents.map(row => [
                                  <Link to={`/pricelists/pricelists/${props.data.id}/selectors/${row.selector.id}#${row.bundle.sku}`}>
                                    {row.bundle.name}
                                  </Link>,
                                  ...(user.isSingleSpace ? [] : [row.bundle.space.name]),
                                  row.bundle.sku,
                                ])}
                              />
                            )}
                          </Grid>
                        )}
                        {(props.data.validation.facilitiesWithoutPrice.length > 0 ||
                          props.data.validation.facilitiesWithoutPriceComponents.length > 0) && (
                          <Grid item xs={12} sm={4}>
                            {props.data.validation.facilitiesWithoutPrice.length > 0 && (
                              <SimpleTable
                                headers={[
                                  i18next.t('pricelist-validation-facilities', {
                                    facilityCount: `${props.data.validation.facilitiesWithoutPrice.length}`,
                                  }),
                                  ...(user.isSingleSpace ? [] : ['']),
                                  '',
                                ]}
                                rows={props.data.validation.facilitiesWithoutPrice.map(row => [
                                  <Link
                                    to={
                                      props.data.basePriceSelector
                                        ? `/pricelists/pricelists/${props.data.id}/selectors/${props.data.basePriceSelector.id}#${row.sku}`
                                        : `/pricelists/pricelists/${props.data.id}`
                                    }
                                  >
                                    {row.name}
                                  </Link>,
                                  ...(user.isSingleSpace ? [] : [row.space.name]),
                                  row.sku,
                                ])}
                              />
                            )}
                            <br />
                            {props.data.validation.facilitiesWithoutPriceComponents.length > 0 && (
                              <SimpleTable
                                headers={[
                                  i18next.t('pricelist-validation-facilitycomponents', {
                                    facilityCount: `${props.data.validation.facilitiesWithoutPriceComponents.length}`,
                                  }),
                                  ...(user.isSingleSpace ? [] : ['']),
                                  '',
                                ]}
                                rows={props.data.validation.facilitiesWithoutPriceComponents.map(row => [
                                  <Link to={`/pricelists/pricelists/${props.data.id}/selectors/${row.selector.id}#${row.facility.sku}`}>
                                    {row.facility.name}
                                  </Link>,
                                  ...(user.isSingleSpace ? [] : [row.facility.space.name]),
                                  row.facility.sku,
                                ])}
                              />
                            )}
                          </Grid>
                        )}
                      </Grid>,
                    ]
                  : []),
                <>
                  <PagedTable
                    filter={
                      canEdit && (
                        <Button startIcon={<AddIcon />} onClick={() => navigate(`/pricelists/pricelists/${props.data.id}/selectors/_create`)}>
                          {i18next.t('pricelist-selector-add')}
                        </Button>
                      )
                    }
                    refetchPage={(skip, take) =>
                      selectorsQuery({
                        variables: {
                          priceListId: props.data.id,
                          skip,
                          take,
                        },
                      })
                    }
                    rowsLoading={selectorsLoading}
                    rowsErr={selectorsError}
                    headers={[
                      i18next.t('pricelist-selector-name'),
                      i18next.t('pricelist-selector-baseprice'),
                      i18next.t('pricelist-selector-minguestcount'),
                      i18next.t('pricelist-selector-maxguestcount'),
                      i18next.t('pricelist-selector-minitemcount'),
                      i18next.t('pricelist-selector-maxitemcount'),
                      i18next.t('pricelist-selector-validfrom'),
                      i18next.t('pricelist-selector-validto'),
                      i18next.t('pricelist-selector-weekdays'),
                      '',
                    ]}
                    rows={(selectorsData?.listPriceSelectors || []).map(selector => [
                      <Link to={`/pricelists/pricelists/${props.data.id}/selectors/${selector.id}`}>{selector.name}</Link>,
                      selector.isBasePrice && <CheckIcon />,
                      selector.minGuestCount,
                      selector.maxGuestCount,
                      selector.minItemCount,
                      selector.maxItemCount,
                      formatDate(selector.validFrom),
                      formatDate(selector.validTo),
                      selector.weekdays && selector.weekdays.join(', '),
                      <ButtonGroup>
                        <PriceSelectorCopyButton id={selector.id} priceListId={selector.priceList.id} spaceId={selector.space.id} icon />
                        <PriceSelectorDeleteButton id={selector.id} priceListId={selector.priceList.id} spaceId={selector.space.id} icon />
                      </ButtonGroup>,
                    ])}
                  />
                </>,
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <GroupedSpaceSelectionInput
                      name="parentPriceListId"
                      control={control}
                      label={i18next.t('pricelist-parent')}
                      list={filterForSelectableRecords(user, props.priceLists.filter(p => p.id !== props.data.id), getValues('spaceId'), false, true)}
                      disabled={!canEdit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  {getValues('parentPriceListId') > 0 && <>
                    <Grid item xs={12}>
                      <SimpleTable
                        name="priceRules"
                        headers={[
                          i18next.t('pricelist-rules-item'),
                          i18next.t('pricelist-rules-rule'),
                          i18next.t('pricelist-rules-rate'),
                          i18next.t('pricelist-rules-rounding'),
                          ''
                        ]}
                        rowIds={rulesFields.map((mr, index: number) => mr.id)}
                        rows={rulesFields.map((mr, index: number) => [
                          _renderRuleScope(index),
                          <FormInputDropdown
                            name={`rules.${index}.rule`}
                            control={control}
                            options={Object.keys(EPricingRuleType).map(p => ({
                              value: p,
                              label: `${i18next.t('enums-EPricingRuleType-' + p)}`,
                            }))}
                            required
                            disabled={!canEdit}
                          />,
                          getValues(`rules.${index}.rule`) === EPricingRuleType.PERCENTAGE ?
                            <FormInputPercentage name={`rules.${index}.rate`} control={control} disabled={!canEdit} />
                          : <FormInputNumber name={`rules.${index}.rate`} control={control} disabled={!canEdit} />,
                          <FormInputDropdown
                            name={`rules.${index}.rounding`}
                            control={control}
                            options={Object.keys(EPricingRoundingType).map(p => ({
                              value: p,
                              label: `${i18next.t('enums-EPricingRoundingType-' + p)}`,
                            }))}
                            required
                            disabled={!canEdit}
                          />,
                          <IconButton onClick={() => rulesRemove(index)}>
                            <DeleteIcon />
                          </IconButton>,
                        ])}
                      />
                    </Grid>
                    {canEdit && <Grid item xs={12}>
                      <Select
                        value={0}
                        displayEmpty
                        onChange={event =>
                          event.target.value &&
                          rulesAppend({
                            productId: event.target.value as number,
                            rule: EPricingRuleType.PERCENTAGE,
                            rounding: EPricingRoundingType.FLOOR,
                            rate: 0,
                          })
                        }
                      >
                        {buildMenuItemsFromOptions(
                          buildGroupedSpaceSelectionOptions(
                            user,
                            filterForSelectableRecords(user, props.products, getValues('spaceId'), false, true),
                            `${i18next.t('pricelist-rules-add-product')}`,
                            0,
                          ),
                        )}
                      </Select>
                      <Select
                        value={0}
                        displayEmpty
                        onChange={event =>
                          event.target.value &&
                          rulesAppend({
                            bundleId: event.target.value as number,
                            rule: EPricingRuleType.PERCENTAGE,
                            rounding: EPricingRoundingType.FLOOR,
                            rate: 0,
                          })
                        }
                      >
                        {buildMenuItemsFromOptions(
                          buildGroupedSpaceSelectionOptions(
                            user,
                            filterForSelectableRecords(user, props.bundles, getValues('spaceId'), false, true),
                            `${i18next.t('pricelist-rules-add-bundle')}`,
                            0,
                          ),
                        )}
                      </Select>
                      <Select
                        value={0}
                        displayEmpty
                        onChange={event =>
                          event.target.value &&
                          rulesAppend({
                            facilityId: event.target.value as number,
                            rule: EPricingRuleType.PERCENTAGE,
                            rounding: EPricingRoundingType.FLOOR,
                            rate: 0,
                          })
                        }
                      >
                        {buildMenuItemsFromOptions(
                          buildGroupedSpaceSelectionOptions(
                            user,
                            filterForSelectableRecords(user, props.facilities, getValues('spaceId'), false, true),
                            `${i18next.t('pricelist-rules-add-facility')}`,
                            0,
                          ),
                        )}
                      </Select>
                      <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={() => {
                        rulesAppend({
                          rule: EPricingRuleType.PERCENTAGE,
                          rounding: EPricingRoundingType.FLOOR,
                          rate: 0,
                        });
                      }}>
                        {i18next.t('pricelist-rules-add-empty')}
                      </Button>
                    </Grid>}
                  </>}
                </Grid>,                
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('pricelist-save')}
            </Button>
          )}
          {canEdit && props.data.id > 0 && <PriceListDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && <PriceListCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={<ImportExportIcon />}
              onClick={async () => {
                try {
                  const res = await exportMutateFunction({
                    variables: {
                      priceListId: props.data.id,
                    },
                  });
                  if (res.data?.exportPriceList) {
                    exportToFile(res.data?.exportPriceList, 'application/json', 'seminargo-prices.json');
                  }
                  dispatchMessage(dispatch, i18next.t('prices-export-ready'));
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('prices-export-run')}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default function PriceList(props: PriceListProps) {
  const priceListQuery = useQuery(PRICELIST_VIEW_QUERY, {
    variables: { id: props.id },
  });
  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);
  const pricelistsQuery = useQuery(PRICELISTS_LIST_QUERY);
  const productsQuery = useQuery(PRODUCTS_LIST_QUERY);
  const bundlesQuery = useQuery(PRODUCTBUNDLES_LIST_QUERY);
  const facilitiesQuery = useQuery(FACILITIES_LIST_QUERY);  

  const loading = hotelsQuery.loading || priceListQuery.loading || pricelistsQuery.loading || productsQuery.loading || bundlesQuery.loading || facilitiesQuery.loading;
  const error = hotelsQuery.error || priceListQuery.error || pricelistsQuery.error || productsQuery.error || bundlesQuery.error || facilitiesQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <PriceListForm
        data={priceListQuery.data!.viewPriceList!}
        hotels={hotelsQuery.data!.listHotels}
        priceLists={pricelistsQuery.data!.listPriceLists}
        products={productsQuery.data!.listProducts}
        facilities={facilitiesQuery.data!.listFacilities}
        bundles={bundlesQuery.data!.listProductBundles}
      />
    );
}

export function PriceListCreate(props: PriceListCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);
  const pricelistsQuery = useQuery(PRICELISTS_LIST_QUERY);
  const productsQuery = useQuery(PRODUCTS_LIST_QUERY);
  const bundlesQuery = useQuery(PRODUCTBUNDLES_LIST_QUERY);
  const facilitiesQuery = useQuery(FACILITIES_LIST_QUERY);  

  const loading = hotelsQuery.loading || pricelistsQuery.loading || productsQuery.loading || bundlesQuery.loading || facilitiesQuery.loading;
  const error = hotelsQuery.error || pricelistsQuery.error || productsQuery.error || bundlesQuery.error || facilitiesQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <PriceListForm
        data={{
          id: -1,
          name: '',
          version: moment().format('YYYY-DD-MM HH:mm:ss'),
          refCode: '',
          isDefault: false,
          isPublished: false,
          isPricesNet: false,
          forLister: true,
          forWidget: true,
          currency: 'EUR',
          validity: [],
          rules: [],
          children: [],
          validation: {
            isComplete: true,
            bundlesWithoutPrice: [],
            bundlesWithoutPriceComponents: [],
            productsWithoutPriceComponents: [],
            facilitiesWithoutPrice: [],
            facilitiesWithoutPriceComponents: [],
            productsWithoutPrice: [],
          },
          space: { id: initialSpaceId(user, filter) } as any,
        }}
        hotels={hotelsQuery.data!.listHotels}
        priceLists={pricelistsQuery.data!.listPriceLists}
        products={productsQuery.data!.listProducts}
        facilities={facilitiesQuery.data!.listFacilities}
        bundles={bundlesQuery.data!.listProductBundles}
      />
    );
}

interface PriceListCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function PriceListCopyButton(props: PriceListCopyButtonProps) {
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [spaceId, setSpaceId] = useState<number>(props.spaceId);

  const user = userSelector()!;
  const canEdit = user.isSeminargo || (user.isAdmin && user.space);

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_PRICELIST_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id, spaceId: spaceId },
        update: cache => EVICT_PRICELISTS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_PRICELISTS_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('pricelist-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon && !canEdit) return null;

  if (props.icon) {
    return (
      <>
        <IconButton disabled={!canEdit || copyMutateLoading} onClick={() => (user.isSingleSpace ? __do() : setDialogOpen(true))}>
          <Tooltip title={i18next.t('pricelist-copy')}>
            <ContentCopyIcon />
          </Tooltip>
        </IconButton>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('pricelist-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('pricelist-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ marginRight: 2 }}
          variant="contained"
          color="secondary"
          disabled={!canEdit || copyMutateLoading}
          startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
          onClick={() => (user.isSingleSpace ? __do() : setDialogOpen(true))}
        >
          {i18next.t('pricelist-copy')}
        </Button>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('pricelist-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('pricelist-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  }
}

interface PriceListDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function PriceListDeleteButton(props: PriceListDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_PRICELIST_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('pricelist-confirm-delete')}
      confirmationTitle={i18next.t('pricelist-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_PRICELISTS_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_PRICELISTS_QUERIES(),
          });
          navigate('/pricelists/pricelists');
          dispatchMessage(dispatch, i18next.t('pricelist-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('pricelist-delete')}
    </ConfirmationButton>
  );
}
