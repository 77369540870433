import React, { useMemo } from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import HotelIcon from '@mui/icons-material/Hotel';
import SpaceIcon from '@mui/icons-material/FolderShared';
import { FormInputDropdown } from '../form/FormInputDropdown';
import { FormSelectOption } from '../form/FormInputProps';
import { userSelector } from 'helper/security';
import { unicodeIndent } from './SpaceSelectionInput';
import { ISpace, IHotel } from 'types';

interface HotelSelectionProps {
  hotels: IHotel[];
  allowSpaceSelection?: boolean;
  name: string;
  label: string;
  emptyLabel?: string | null;
  emptyValue?: any;
  control: any;
  [key: string]: any;
}

export const buildHotelSelectionOptions = (
  spaces: ISpace[],
  hotels: IHotel[],
  allowSpaceSelection: boolean,
  emptyLabel?: React.ReactNode,
  emptyValue?: any,
) => {
  const options = [] as FormSelectOption[];
  if (emptyLabel) {
    options.push({
      label: <em>{emptyLabel}</em>,
      value: emptyValue !== undefined ? emptyValue : 0,
    });
  }

  const _addSpace = (space: ISpace, level: number = 0) => {
    options.push({
      label: (
        <>
          {unicodeIndent(level)} <SpaceIcon /> {space.name}
        </>
      ),
      filterText: space.name,
      value: allowSpaceSelection ? { spaceId: space.id, hotelId: null } : emptyValue !== undefined ? emptyValue : 0,
      disabled: !allowSpaceSelection,
    });
    const spaceHotels = hotels.filter(h => h.space.id === space.id);
    for (const spaceHotel of spaceHotels) {
      options.push({
        label: (
          <>
            {unicodeIndent(level + 1)} <HotelIcon /> {spaceHotel.name}
            {spaceHotel.extRefCode && ` (${spaceHotel.extRefCode})`}
          </>
        ),
        filterText: spaceHotel.name,
        value: allowSpaceSelection ? { spaceId: space.id, hotelId: spaceHotel.id } : spaceHotel.id,
      });
    }

    if (space.children && space.children.length > 0) {
      const children = spaces.filter(fs => (space.children || []).findIndex(cc => fs.id == cc.id) >= 0);
      for (const child of children) {
        _addSpace(child, level + 1);
      }
    }
  };

  const spaceIds = _.uniq(hotels.map(h => h.space.id));
  if (spaceIds.length > 1) {
    const rootSpaces = spaces.filter(s => !s.parent);
    for (const rootSpace of rootSpaces) {
      _addSpace(rootSpace, 0);
    }
  } else {
    options.push(
      ...hotels.map(h => ({
        label: (
          <>
            <HotelIcon /> {h.name}
            {h.extRefCode && ` (${h.extRefCode})`}
          </>
        ),
        filterText: h.name,
        value: allowSpaceSelection ? { spaceId: h.space.id, hotelId: h.id } : h.id,
      })),
    );
  }
  return options;
};

export const HotelSelectionInput = (props: HotelSelectionProps) => {
  const { hotels, allowSpaceSelection, name, label, emptyLabel, emptyValue, control, ...rest } = props;
  const user = userSelector()!;

  const options = useMemo(
    () =>
      buildHotelSelectionOptions(user.spaces || [], hotels, !!allowSpaceSelection, emptyLabel || i18next.t('generic-selection-empty'), emptyValue),
    [user, hotels],
  );

  return <FormInputDropdown name={name} control={control} label={label} options={options} {...rest} />;
};
