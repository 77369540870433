import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Grid } from '@mui/material';

import { useLazyQuery, useQuery, useQueryRefHandlers } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { PUBLIC_CLIENT_LIST_SEARCH_QUERY, PUBLIC_COMPANIES_LIST_SEARCH_QUERY } from './gql';

import { ErrorAlert, RedirectError } from 'pages/error';
import SimpleTable from 'components/table/SimpleTable';
import { HL_PublicClientOutput, HL_PublicCompanyOutput, HotelListOutput } from '__generated__/graphql';

interface HotelsListProps {
  hotels: HotelListOutput[];
}

export default function HotelsList(props: HotelsListProps) {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  return (
    <>
      <Helmet>
        <title>{i18next.t('clients-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SimpleTable
           headers={[
            i18next.t('hotels-list-header-name'),
            i18next.t('hotels-list-header-businesscountry'),
            i18next.t('hotels-list-header-email'),
          ]}
          rows={(props.hotels || []).map((row, index) => [
            <Link to={`/settings/hotels/${row.id}`}>{row.name}</Link>,
            row.businessCountry,  
            row.businessEmail
          ])}
          />
        </Grid>
      </Grid>
    </>
  );
}
