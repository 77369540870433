import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';

import { userSelector, hasAnyAdminSpace } from 'helper/security';
import i18next from 'i18next';
import ClientsCompaniesList from './companies';
import ClientsList from './clients';
import PublicClient from './client';
import PublicCompany from './company';

export default function ClientsIndex() {
  const location = useLocation();
  const user = userSelector()!;

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Tabs value={location.pathname === '/clients' ? '/clients/companies' : location.pathname.split('/').slice(0, 3).join('/')}>
            <Tab value="/clients/companies" label={i18next.t('clients-tabs-header-companies')} component={Link} to="/clients/companies" />
            <Tab value="/clients/clients" label={i18next.t('clients-tabs-header-clients')} component={Link} to="/clients/clients" />
          </Tabs>

          <Box p={2}>
            <Routes>
              <Route index element={<ClientsCompaniesList />} />
              <Route path="companies" element={<ClientsCompaniesList />} />
              <Route path="clients" element={<ClientsList />} />
              <Route path="clients/:id" Component={() => <PublicClient id={parseInt(useParams().id!)} />} />
              <Route path="companies/:id" Component={() => <PublicCompany id={parseInt(useParams().id!)} />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
