import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

export const LIST_TASKS = gql(`
  query ListTasks {
    listTasks {
      id
      description
      dueDate
      status
      priority
      categoryId
      category {
        id
        name
      }
      subjectId
      subject {
        id
        name
      }
      assigneeId
      assignee {
        id
        name
      }
      createdById
      createdBy {
        id
        name
      }
      hotelId
      hotel {
        id
        name
        businessName
      }
      bookingId
      booking {
        id
        title
        refCode
      }
      publicClientId
      publicClient {
        id
        firstname
        lastname
      }
      publicCompanyId
      publicCompany {
       id
       name
       isPremium
      }
      comments {
        id
        comment
        createdAt
        user {
          id
          name
        }
      }
    }
  }
`);

export const VIEW_TASK = gql(`
  query ViewTask($id: Int!) {
    viewTask(id: $id) {
      id
      description
      dueDate
      status
      priority
    }
  }
`);

export const CREATE_TASK = gql(`
  mutation CreateTask($input: TM_TaskInput!) {
    createTask(input: $input) {
      id
      description
      dueDate
      status
      priority
    }
  }
`);

export const UPDATE_TASK = gql(`
  mutation UpdateTask($id: Int!, $input: TM_TaskInput!) {
    updateTask(id: $id, input: $input) {
      id
      description
      dueDate
      status
      priority
    }
  }
`);

export const LIST_TASK_CATEGORIES = gql(`
  query ListTaskCategories {
    listTaskCategories {
      id
      name
      disabled
      system
      groupId
      group {
        id
        name
      }
    }
  }
`);

export const LIST_TASK_CATEGORY_GROUPS = gql(`
  query ListTaskCategoryGroups {
    listTaskCategoryGroups {
      id
      name
      disabled
      system
    }
  }
`);

export const LIST_TASK_SUBJECTS = gql(`
  query ListTaskSubjects {
    listTaskSubjects {
      id
      name
      disabled
      system
      categoryId
      category {
        id
        name
        group {
          id
          name
        }
      }
    }
  }
`);

export const VIEW_TASK_CATEGORY = gql(`
  query ViewTaskCategory($id: Int!) {
    viewTaskCategory(id: $id) {
      id
      name
      disabled
      system
      groupId
    }
  }
`);

export const VIEW_TASK_CATEGORY_GROUP = gql(`
  query ViewTaskCategoryGroup($id: Int!) {
    viewTaskCategoryGroup(id: $id) {
      id
      name
      disabled
      system
    }
  }
`);

export const VIEW_TASK_SUBJECT = gql(`
  query ViewTaskSubject($id: Int!) {
    viewTaskSubject(id: $id) {
      id
      name
      disabled
      system
      descriptionTemplate
      categoryId
    }
  }
`);

export const LIST_TASK_USERS = gql(`
  query ListTaskUsers {
    listTaskUsers {
      id
      name
    }
  }
`);

export const LIST_TASK_HOTELS = gql(`
  query ListTaskHotels {
    listTaskHotels {
      id
      name
      businessName
    }
  }
`);

export const LIST_TASK_BOOKINGS = gql(`
  query ListTaskBookings {
    listTaskBookings {
      id
      title
      refCode
    }
  }
`);

export const LIST_TASK_PUBLIC_CLIENTS = gql(`
  query ListTaskPublicClients {
    listTaskPublicClients {
      id
      firstname
      lastname
      publicCompanyId
    }
  }
`);

export const LIST_TASK_PUBLIC_COMPANIES = gql(`
  query ListTaskPublicCompanies {
    listTaskPublicCompanies {
      id
      name
    }
  }
`);

export const VIEW_TASK_FILTER = gql(`
  query ViewTaskFilter {
    viewTaskFilter {
      status
      dueFrom
      dueTo
      assignees {
        id
      }
      categories {
        id
      }
      publicCompanies {
        id
      }
      publicClients {
        id
      }
      hotels {
        id
      }
    }
  }
`);

export const UPDATE_TASK_FILTER = gql(`
  mutation UpdateTaskFilter($input: TM_TaskFilterInput!) {
    updateTaskFilter(input: $input) {
      status
    }
  }
`);

export const UPDATE_TASK_CATEGORY = gql(`
  mutation UpdateTaskCategory($id: Int!, $data: TM_TaskCategoryInput!) {
    updateTaskCategory(id: $id, data: $data) {
      id
      name
      disabled
      system
      groupId
    }
  }
`);

export const CREATE_TASK_CATEGORY = gql(`
  mutation CreateTaskCategory($data: TM_TaskCategoryInput!) {
    createTaskCategory(data: $data) {
      id
      name
      disabled
      system
      groupId
    }
  }
`);

export const UPDATE_TASK_CATEGORY_GROUP = gql(`
  mutation UpdateTaskCategoryGroup($id: Int!, $data: TM_TaskCategoryGroupInput!) {
    updateTaskCategoryGroup(id: $id, data: $data) {
      id
      name
      disabled
      system
    }
  }
`);

export const CREATE_TASK_CATEGORY_GROUP = gql(`
  mutation CreateTaskCategoryGroup($data: TM_TaskCategoryGroupInput!) {
    createTaskCategoryGroup(data: $data) {
      id
      name
      disabled
      system
    }
  }
`);

export const UPDATE_TASK_SUBJECT = gql(`
  mutation UpdateTaskSubject($id: Int!, $data: TM_TaskSubjectInput!) {
    updateTaskSubject(id: $id, data: $data) {
      id
      name
      disabled
      system
      descriptionTemplate
      categoryId
    }
  }
`);

export const CREATE_TASK_SUBJECT = gql(`
  mutation CreateTaskSubject($data: TM_TaskSubjectInput!) {
    createTaskSubject(data: $data) {
      id
      name
      disabled
      system
      descriptionTemplate
      categoryId
    }
  }
`);

export const ADD_TASK_COMMENT = gql(`
  mutation AddTaskComment($taskId: Int!, $comment: String!) {
    addTaskComment(taskId: $taskId, comment: $comment) {
      id
      comment
    }
  }
`);