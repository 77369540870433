import React from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, CircularProgress, Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';

import SimpleTable from '../../components/table/SimpleTable';
import { FormatDateTime } from '../../components/DateTime';
import FileUploadButton from '../../components/FileUploadButton';
import { dispatchException, dispatchMessage } from '../../helper/snackbar';
import { exportToFile } from '../../helper/download';
import { filterSelector } from '../../helper/filter';
import { userSelector, hasAnyAdminSpace } from '../../helper/security';

import {
  LIST_TASK_CATEGORY_GROUPS
} from './gql';

import { ErrorAlert } from '../error';

export default function TasksSettingsGroups() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filter = filterSelector();
  const user = userSelector()!;

  const { loading, error, data } = useQuery(LIST_TASK_CATEGORY_GROUPS);

  return (
    <>
      <Helmet>
        <title>Task Einstellungen / Bereiche</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  "Name",
                  "Systemrelevant",
                  "Status"
                ]}
                rows={data.listTaskCategoryGroups.map(row => [
                  <Link to={`/tasks/settings/groups/${row.id}`}>{row.name}</Link>,
                  row.system ? <CheckIcon /> : null,
                  row.disabled ? 'Deaktiviert' : 'Aktiv'
                ])}
              />
            </Grid>
            <Grid item xs={12}>
              {hasAnyAdminSpace(user) && (
                <Button
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/tasks/settings/groups/_create')}
                >
                  Bereich hinzufügen
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
