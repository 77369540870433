import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Grid } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { PUBLIC_COMPANY_LIST_QUERY } from './gql'

import { ErrorAlert, RedirectError } from 'pages/error';

export default function ClientsCompaniesList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(PUBLIC_COMPANY_LIST_QUERY);
  if (error) return <RedirectError err={error} />

  return (
    <>
      <Helmet>
      <title>{i18next.t('clients-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            refetchPage={(skip, take) =>
              query({
                variables: {
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            headers={[
              i18next.t('publiccompany-list-header-name'),
              i18next.t('publiccompany-list-header-address'),
              i18next.t('publiccompany-list-header-premium'),
              i18next.t('publiccompany-list-header-clients-count'),
            ]}
            rows={(data?.listPublicCompanies || []).map((row, index) => [
              <Link to={`/clients/companies/${row.id}`}>{row.name}</Link>,
              row.address + ', ' + row.zip + ' ' + row.city,
              row.isPremium ? <CheckIcon /> : null,
              row.clientsCount === 1 ? row.clientsCount + ' Ansprechperson' : row.clientsCount + ' Ansprechpersonen',
            ])}
          />
        </Grid>
      </Grid>
    </>
  );
}
